/*
Template Name: magarisa - Admin & Dashboard Template
Author: Themesbrand
Version: 3.1.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/


//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/dragula";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";

@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/timepicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/datatable";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/echarts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";
@import "custom/plugins/leaflet-maps";


// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/file-manager";
@import "custom/pages/chat";
@import "custom/pages/projects";
@import "custom/pages/contacts";
@import "custom/pages/crypto";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";

@import "custom/custom-styles";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&family=Quicksand:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

html, body { height: 100%; width: 100%; margin: 0; }
body {
  background:#f8f8f8;  
  /*padding-top: 4.5rem;*/
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: #505D68;
  margin: 0;
}

// font-family: 'Inter', sans-serif;
// font-family: 'Quicksand', sans-serif;
h1, h2, h3, h4 {
  font-family: 'Quicksand', sans-serif;
  font-weight:bold;
}
p, .fontInter {
  // font-family: 'Inter', sans-serif!important;  
  // font-size:16px;
  font-family: 'Inter'!important;  
  font-size:12px;
  // font-size: 15px;
}

.inter-font{
  font-family: 'Inter'!important;  
}

.quicksand-font{
  font-family: sans-serif !important;
}

.quizCntrlBtns {
  min-width:110px;
  height:44px;
  text-align: center;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  margin-right:10px;
}
.quizCntrlBtnsGrey {
  /*background:#5E5E5E;*/
  color:#fff;
  text-transform: uppercase;
  border:solid 1px #5E5E5E;
}

// background:#003399;

// b4- mod- color in assesment page 
// .quizCntrlBtnsFill {
//   background:#7900E9;
//   color:#fff;
// }

// .quizCntrlBtnsOutlined {
//   border: solid 1px #7900E9;
//   color: #7900E9;
// }
// .quizCntrlBtnsFill:hover, .quizCntrlBtnsFill:active, .quizCntrlBtnsFill:focus {
//   border: solid 1px #7900E9;
//   color: #7900E9;
// }
// .quizCntrlBtnsOutlined:hover, .quizCntrlBtnsOutlined:active, .quizCntrlBtnsOutlined:focus {
//   border: solid 1px #7900E9;
//   color: #7900E9;
// }


.quizCntrlBtnsFill {
  background:#003399;
  color:#fff;
}

.quizCntrlBtnsOutlined {
  border: solid 1px #003399;
  color: #003399;
}
.quizCntrlBtnsFill:hover, .quizCntrlBtnsFill:active, .quizCntrlBtnsFill:focus {
  border: solid 1px #003399;
  color: #003399;
}
.quizCntrlBtnsOutlined:hover, .quizCntrlBtnsOutlined:active, .quizCntrlBtnsOutlined:focus {
  border: solid 1px #003399;
  color: #003399;
}




.quizCntrlBtnsOutlinedGrey:hover, .quizCntrlBtnsOutlinedGrey:active, .quizCntrlBtnsOutlinedGrey:focus {  
  border: solid 1px #A1AEB7;
  color: #A1AEB7;
}
.fontWeightNormal {
  font-weight: normal;
}
.quizTitle {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
#sidebar-menu .has-arrow:after {  
  margin-right: 14px !important;
}
.buttonClass {
  width:4em;
  height:100%;
  // background:#aa1616!important;
  background: url('../../assets/icons/multiangle.svg') no-repeat 50% 50% !important;
}
.dropup {
  min-width:250px;
  padding:5px;
}
.dropup-content {  
  bottom: 30px;    
}
.dropup-content a { 
  padding:10px;
}
.list-group-item-div {
  display: flex;
  align-items:center;
}

.video-container {
  width: 100%;
  height: auto;
  margin: 30px auto 50px;
  position: relative;
  overflow: hidden;            
}



/*External Button Controls*/
.vjs-user-inactive .vjs-custom-button  {
  display: none;
}
.vjs-user-active .vjs-custom-button {
  display: block;
}
.vjs-user-inactive ~ .vjs-custom-button  {
  display: none;
}
.vjs-user-active ~ .vjs-custom-button {
  display: block;
}
.vjs-user-active .vjs-control-bar, .vjs-user-inactive .vjs-control-bar {
  display: flex;
  justify-content: space-evenly !important;

}

.video-js .vjs-progress-control .vjs-progress-holder {
  margin: 0 0.45em 0 0.45em !important;
  z-index: 10;
}

.vjs-tech{
  display: flex;
  justify-content: center;
  align-items: center;
}

.vjs-icon-angle:before {
  content: "\F0EF6";
}

.vjs-custom-controlbtns{
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: transparent;
  top:0;
  gap:20px;
  pointer-events: none;
}

.end-container{
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top:0;
  // top:35%;
  pointer-events: none;
}

.end-card-btn{
  pointer-events: all;
}

.vjs-custom-end-activity{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:10px;
  padding:40px;
  border-radius: 10px;
  border: 1px solid #fff;
  background-color: rgba(0, 0, 0, 0.7);
}

.vjs-ea-title{
  font-size: 20px;
}

.vjs-custom-play-button {
  position: relative;
  // transform: translateY(-50%);
  width: 42px;
  height: 42px;
  border-radius: 50%;   
  font-size: 40px;  
  cursor: pointer;
  pointer-events:all;
  // background: url('../../assets/icons/fill-play-btn.svg') no-repeat;
  // background-size: cover;
}
.vjs-custom-pause-button {
  position: relative;
  // transform: translateY(-50%);
  width: 42px;
  height: 42px;
  border-radius: 50%;   
  font-size: 40px;  
  cursor: pointer;
  pointer-events:all;
  // background: url('../../assets/icons/fill-pause-btn.svg') no-repeat;
  // background-size: cover;
}
.vjs-custom-multiangle-button {
  position: absolute;
  bottom:80px;
  right:50px;   
  width: 60px;
  height: 60px;
  border-radius: 50%;    
  border:3px solid #fff;
  padding: 1px;
  font-size: 25px;  
  line-height: 3.4rem;
  background-color: rgba(255,255,255,0.5);
  cursor: pointer;
  // background: url('../../assets/icons/clr-multiangle.svg') no-repeat;
  // background-size: cover;
}
.vjs-fullscreen.vjs-user-inactive .vjs-custom-multiangle-button,
.vjs-fullscreen.vjs-user-active .vjs-custom-multiangle-button {
  bottom:140px;
}
.vjs-custom-forward-button {
  position: relative;
  // transform: translateY(-50%);
  width: 42px;
  height: 42px;
  border-radius: 50%;   
  font-size: 40px;  
  pointer-events:all;
  cursor: pointer;
  // background: url('../../assets/icons/forward.svg') no-repeat;
  // background-size: cover;  
}
.vjs-custom-backward-button {
  position: relative;
  // transform: translateY(-50%);
  width: 42px;
  height: 42px;
  border-radius: 50%;   
  font-size: 40px;  
  pointer-events:all;
  cursor: pointer;
  // background: url('../../assets/icons/backward.svg') no-repeat;
  // background-size: cover;
}
.vjs-custom-nextvideo-button {
  position: relative;
  // transform: translateY(-50%);
  width: 42px;
  height: 42px;
  border-radius: 50%;   
  font-size: 40px;  
  cursor: pointer;
  pointer-events:all;
  // background: url('../../assets/icons/next-video.svg') no-repeat;
  // background-size: cover;
}
.vjs-custom-prevideo-button {
  position: relative;
  // transform: translateY(-50%);
  width: 42px;
  height: 42px;
  border-radius: 50%;   
  font-size: 40px;  
  cursor: pointer;
  pointer-events:all;
  // background: url('../../assets/icons/prev-video.svg') no-repeat;
  // background-size: cover;
}
.vjs-custom-multiangle-button:hover {
  // -webkit-animation:spin .5s linear 1;
  // -moz-animation:spin .5s linear 1;
  // animation:spin .5s linear 1;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(90deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(90deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(90deg); transform:rotate(90deg); } }

/*Layout Styling 20/05/2022*/
.col {
  border-right:solid 1px #ccc;
}
.navColumn {
  height:100vh;
  width:484px;
  padding:25px 30px;
  box-shadow: 3px 4px 17px 0px rgba(136, 136, 136, 0.5);
  overflow: auto;
}
.contentColumnWrapper {  
  margin-left:522px;
  //background:rgba(240, 240, 240, 0.5);
  background:#fff;
  padding-bottom: 30px;
}
.ntLoggedinContentColumnWrapper {
  margin-left:0;
}
.contentColumnWrapper.contentColumnWrapperStudioMode {
  margin-left:0!important;
}
.contentColumn {
  width:1126px;
  margin:0 auto;  
  /*padding:15px;*/
  /*height:100vh;*/
}
.hamburgerMenuWrapper {  
  position:absolute;
  top:150px;
  left:-5px;
  display:none;
}
.hamburgerMenuWrapper button {
  display: flex;
  align-items: center;  
  width:35px;
  height: 30px;
  font-size:15px;
  background: #7900E9;
}
.hamburgerMenuWrapper button:active, .hamburgerMenuWrapper button:focus {
  background: #7900E9;
}
.hamburgerMenuWrapper button:hover {
  width:165px;
  background: #7900E9;
}
.hamburgerMenuWrapper button span {
  visibility: hidden;
  margin-left: 5px;
}
.hamburgerMenuWrapper button:hover span {
  visibility: visible;
}
@media only screen and (max-width: 1365px) {
  
}
@media only screen and (max-width: 935px) {
  .center-content {
    width:auto;
    padding:0 60px;
  }
}
@media only screen and (min-width: 1919px) {
  .contentColumn {
    margin: 0 0 0 250px;
  }
  .contentColumnDiv {
    margin: 0 0 0 250px;
  } 
}

.vjs-controls-disabled .vjs-control-bar,
.vjs-using-native-controls .vjs-control-bar,
.vjs-error .vjs-control-bar {
display: flex !important;
}
.vjs-theme .vjs-progress-control {
  position: absolute!important;
  width: 98%!important;
  bottom: 80px!important;
}
.vjs-slider {
  
}
.vjs-theme .vjs-control-bar {
  background: #E4E4E4!important;  
}



.vjs-theme .vjs-playback-rate-value {    
  text-indent: 99999px;
  background: url('../../assets/icons/play-speed-icon.svg') no-repeat;
  background-size: 24px;
  background-position: center center;
}
.vjs-theme .fscreenBtn {
  background: url('../../assets/icons/fullscreen.svg') no-repeat;        
  background-position: center center;
  width: 32px;
  height: 32px;
  position: relative;
  background-size: 22px;
}
.vjs-theme .fscreenBtn.active {
  background: url('../../assets/icons/fullscreen-active.svg') no-repeat;
  background-position: center center;
  width: 32px;
  height: 32px;
  position: relative;
  background-size: 22px;
}
.vjs-theme .studioModeBtn {
  background: url('../../assets/icons/studio-mode.svg') no-repeat;    
  background-position: center center;
  width: 40px;
  height: 32px;
  position: relative;
  background-size: 36px 20px;
}
.vjs-theme .studioModeBtn.active {
  background: url('../../assets/icons/studio-mode-active.svg') no-repeat;  
  background-position: center center;
  width: 40px;
  height: 32px;
  position: relative;
  background-size: 36px 20px;  
}
.vdoOverlay {
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
  font-size:24px;
  font-family: 'Quicksand', sans-serif;
  font-weight:bold;
  opacity:0.5;
  display: block!important;
}
.vjs-theme .vjs-has-started.vjs-user-inactive.vjs-playing .vjs-preview-bar,
.vjs-theme .vjs-has-started.vjs-user-active.vjs-playing .vjs-preview-bar,
.vjs-theme .vjs-fullscreen.vjs-user-inactive .vjs-preview-bar,
.vjs-theme .vjs-fullscreen.vjs-user-active .vjs-preview-bar {
  opacity:0.5!important;
  display: block!important;
}
.vdoOverlayNotReg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}
.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}
.visible {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s 2s, opacity 2s linear;
}
.ant-drawer {
  z-index:999999!important;
}
@media (min-width: 1200px) {
  body[data-layout="horizontal"] .navbar-header {
    max-width: 100%;
  }
}
.dropdown-menu-end {
  min-width:200px;
}
.magarisaBtn {
  font-family: 'Inter', sans-serif!important;
}
.primaryMagarisaColor {
  color:#7900E9;
  // color: #F9A01B;
}
.primaryMagarisaColor:hover {
  color:rgba(121, 0, 233, 0.9);
  // color: #FEBE10;
}
.primaryMagarisaBgColor {
  background:#7900E9;
  color:#fff;
}
.primaryMagarisaBgColorOutline {
  background: transparent;
  border: solid 1px #7900E9;
  color:#7900E9!important;
}
.primaryMagarisaBgColorOutline:hover, .primaryMagarisaBgColorOutline:active,
.primaryMagarisaBgColorOutline:focus {
  // background:#7900E9;
  background: #FFFFFF;
  border: solid 1px #7900E9;
  // color:#fff!important;
}

.primaryMagarisaBgNone, .primaryMagarisaBgNone.btn-secondary {
  background:transparent;
  border: none;
  color:#7900E9!important;
  font-family: 'Inter', sans-serif;
  font-size:12px;
  font-weight:500;
  text-transform: uppercase;    
  line-height: 15px;
  position: relative;  
}
.primaryMagarisaBgNone i {
  font-size:18px;
  position: absolute;  
}
.primaryMagarisaBgNone svg {
  margin-top:-5px;
}
.primaryMagarisaBgNone:hover, 
.primaryMagarisaBgNone:active,
.primaryMagarisaBgNone:focus,
.primaryMagarisaBgNone.btn-secondary:hover, 
.primaryMagarisaBgNone.btn-secondary:active,
.primaryMagarisaBgNone.btn-secondary:focus,
.btn-secondary:active, .btn-secondary.active, 
.show > .btn-secondary.dropdown-toggle
{
  background:transparent!important;
  border: none!important;
  color:#7900E9!important;
  box-shadow: none!important;
}
.primaryMagarisaBgColorOutline:hover, .primaryMagarisaBgColorOutline:active,
.primaryMagarisaBgColorOutline:focus {
  // background:#7900E9;
  border: solid 1px #7900E9;
  color: #7900E9 !important;
}
.preferenceBtn {
  width: 100%;
  background: rgba(242, 245, 247, 1);
  border: 4px solid rgba(161, 174, 183, 1);
  color: rgba(94, 94, 94, 1)!important;
  height: 66px;
  border-radius: 15px;
  text-align: left;
  padding-left: 25px;
  font-family: "Quicksand";
  font-size: 1.313rem;
  font-weight: 700;
  margin-top: 15px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.41' height='12' viewBox='0 0 7.41 12'%3E%3Cpath d='M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z' transform='translate(-8.59 -6)' fill='%237900E9'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 24px center;
}
.preferenceBtn:hover  {  
  background: #7900E9;
  border: 4px solid #7900E9;
  color: #fff!important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.41' height='12' viewBox='0 0 7.41 12'%3E%3Cpath d='M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z' transform='translate(-8.59 -6)' fill='%23fff'/%3E%3C/svg%3E")!important;
  background-repeat: no-repeat;
  background-position: right 24px center;
}
.inputStyle {
  height:48px;
  border-radius: 8px;
  background: #fff;
  border: 0.5px solid #848484;
  font-family: "Inter", sans-serif;
  font-size:18px;
  color: #383838;
  
}
.form-control:disabled, .form-control[readonly] {
  /*background-color: rgba(121, 0, 233, 0.1);*/
  background-color: #fff;  
  opacity: 0.5;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height:48px;
  border-radius: 8px;
  border: 0.5px solid #848484;
}
.ant-select-single .ant-select-selector .ant-select-selection-item, 
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 48px;
}
.pricingSelection .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height:58px!important;
  border-radius: 0px!important;
}
.pricingSelection .ant-select-single .ant-select-selector .ant-select-selection-item, 
.pricingSelection .ant-select-single .ant-select-selector .ant-select-selection-placeholder  {
  line-height: 58px!important; 
  font-size:13px;
  font-family:'Inter', sans-serif;
}
.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #3A4956!important;
  border-radius: 50%;
  visibility: hidden;
  -webkit-animation: antRadioEffect 0.36s ease-in-out;
  animation: antRadioEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  content: '';
}
.ant-radio-inner::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
  background-color: #3A4956;
  border-top: 0;
  border-left: 0;
  border-radius: 16px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.ant-radio-checked .ant-radio-inner {
  border-color: #3A4956!important;
}
.ant-drawer-body {
  padding:0 24px;
}
.prefCol {
  width:20%;
  margin:2%;
}

.navLink {
  font-family: 'Inter', sans-serif;
  font-size:16px;
}
.magarisaGradientButton {  
  background-image: linear-gradient(to bottom, #D70E37 0%, #7900E9 100%);
  height:58px;
  color:#fff;
  border:none;
  border-radius: 8px;  
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;  
  letter-spacing: 0.75px;
  text-transform: uppercase;
}
.magarisaGradientButton-small {  
  background-image: linear-gradient(to bottom, #D70E37 0%, #7900E9 100%);
  height:auto;
  color:#fff;
  border:none;
  border-radius: 8px;
  font-size: 16px;
  padding:10px;
  text-align: center;
  text-transform: capitalize;
}
.reviewPending {  
  // background-image: linear-gradient(to bottom, rgba(121, 0, 233,0.5) 0%, rgba(121, 0, 233,0.5) 100%);
  background-image: linear-gradient(to bottom, rgba(211, 211, 211, 0.8) 0%, rgba(68, 68, 68, 0.8) 100%);
}
.resubmit {  
  background-image: linear-gradient(to bottom, rgba(233, 54, 0, 0.8) 0%, rgba(233, 54, 0, 0.8) 100%);
}
.ant-drawer-title {
  font-size: 21px;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  text-transform: capitalize;
}
.ant-drawer-close {
  position: absolute;
  right:0;
}
.ant-drawer-close svg {
  width: 1.5em;
  height: 1.5em;
  fill: #000;
}
// .react-multi-carousel-dot button {
//   color: #7900E9!important;
// }
.textOverFlowEllipsis {
  display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
}
.textOverFlowEllipsisHeader {
  display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
text-transform: capitalize;
}





.textCapitalize {
  text-transform: capitalize;
}
.textUppercase {
  text-transform: uppercase;
}
.courseDescription h2, .courseDescription h2 span {
  font-size: 24px;
  font-family: 'Quicksand', sans-serif!important;
  font-weight:500;
  color:#7900E9!important;
}
.courseDescription p, .courseDescription p span {
  font-size: 15px!important;
  font-family: 'poppins', sans-serif!important;
  line-height:20px;
  color:#101820!important;
  // color:#3A4956!important;
  text-align: left!important;
}
.courseDescription p span strong, .courseDescription p strong {
  // color:#7900E9!important;
}
.metrics p {
  text-transform: uppercase;
  color:#979797;
  font-size:16px;
  font-weight: 500;
}
.artistName {
  text-transform:lowercase;
  text-transform:capitalize;
}
.list-group-item, .topLevelListItem {
  text-transform: capitalize;
  font-size: 16px!important;
  font-family: 'Inter', sans-serif!important;
  color: #5E5E5E!important;
}
.list-group-item svg {
  fill:#7900E9;
}
.list-group-item a {
  margin-top:-5px;
}
.masterPageBg {
  background-image: linear-gradient(to bottom, #5034ed 0%, #7900E9 100%);
  height: 265px;  
}
.emulated-flex-gap {  
  /*display: grid;
  grid-template-columns: repeat(auto-fit, minmax(148px, 1fr));*/
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
.emulated-flex-gap .item {  
  background: #ececec;  
  text-align: center;
  padding:10px; 
}
.avLabel .form-label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 1.5px;
  text-transform: uppercase;

  color: #5E5E5E;
}
.avLabel .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {  
  border: 0.5px solid #ced4da;
}
.ant-drawer-header {
  border-bottom: none;
}
.qaprocessCntr {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #383838;
}
.timerDiv .root-react-component-countdown-timer .border .count {
  border:none;
  font-size:14px;
  padding: 0;
}
.timerDiv .border {
  border:none!important;
}
.urAns {
  background: #F1F1F1;
  padding: 15px;
  color: #5E5E5E;  
}
.questionAnswered {
  width: 22px;
  height: 22px;
  background: #000;
  border-radius: 50%;
  text-align: center;
  color:#fff;
  font-size: 12px;
  font-weight: 500;
}
.questionUnAnswered {
  width: 22px;
  height: 22px;
  background: transparent;
  border: solid 1px #000;
  border-radius: 50%;
  text-align: center;
  color:#000;
  font-size: 12px;
  font-weight: 500;
}
.customSweetAlertIcn {
  width:64px;
  height: 64px;
  background: url('../icons/image-big.svg') no-repeat;
  margin: 0 auto 25px;
}
.customSweetAlertTxt {
  // font-family: 'Quicksand';
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;  
  text-align: center;
  color: #3A4956;
  margin-bottom: 25px;
}
.customSweetAlertButton {
  margin-bottom: 30px;
}
.sweet-alert span.btn {
  font-size: 24px!important;
  font-weight: 500!important;
  color: #7900E9!important;
}
.textBold {
  font-weight: 500;
}
.ant-drawer-close svg {
  width:1em;
  height:1em;
}
.prefConfirmBtn {
  width: 100%;
  background: rgba(242, 245, 247, 1);
  border: 4px solid rgb(204, 204, 204);
  color: rgba(58, 73, 86, 1)!important;
  height: 52px;
  border-radius: 15px;
  text-align: center;
  padding-left: 25px;
  font-family: "Quicksand";
  font-size: 1rem;
  font-weight: 500;
  margin-top: 15px;
  letter-spacing: 0.75px;
}
.prefConfirmBtn:hover  {  
  background: transparent;
  border: 4px solid rgb(204, 204, 204);
  color: rgb(145, 145, 145)!important;
}
.artistPageBd .breadcrumb-item > a, 
.artistPageBd .breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}
.artistInfo {
  font-family: "Inter", sans-serif;
  font-size: 28px;
  font-weight: 500;
  text-transform: capitalize;
  color:#fff;
  text-align: left;
  border-right: 2px solid rgba(255,255,255,0.5);
  padding: 0 15px;
}
.artistInfoLastChild {
  padding-right:0;
  border-right: none;
}
.artistInfo h1, .artistInfo p {
  color:#fff;
  margin:0;
  padding:0;
}
.hTitle {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 26px;
  color: #3A4956;
}
.ant-image-img {
  border-radius: 50%;
  width: 100%;
  height: auto;
  border-radius: 50%;
}
.ant-progress-success-bg, .ant-progress-bg {
  background-color: #5E5E5E;
}
.courseTitle {
  /* H5 Headline */
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;  
  /* Mauve */
  color: #7900E9;
  padding:0 2px;
  margin:0 0 5px 0;
  text-align: left;
  text-transform: capitalize;
}

.webviewer iframe {
  height:800px;
}

.deleteBtn svg path:last-child {
  fill:grey;
}
._1ceqH ._dt3-T {
  min-height:185px!important;
}
._f2DT8 span {
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px!important;
  color: #7900E9!important;
  text-transform: capitalize;
}
._1ceqH ._qxztz {
  padding:0 10px 40px;
}
._3nQu5 {
  margin-right:0!important;
}
._1ceqH ._qxztz ._3nQu5, ._1ceqH ._qxztz ._1bSom {
  background: #7900E9;
}
._1ceqH ._oEOY- {
  background: #fff;
}
._1ceqH ._dt3-T, ._1ceqH ._3bC73 {
  border-radius: 8px!important;
}
#page-topbar {
  left:0;
}
.navbar-brand-box {
  width: 200px;
}
.offCanvasNav .nav-item {
  padding:15px 0;
  border-bottom: solid 1px #ccc;
}
.offCanvasNav .dropdown {
  padding: 15px 0;
  font-family: 'Inter', sans-serif;
}
.offCanvasNav .dropdown button {
  padding-left:0;
}
.offCanvasDrpDwnToggleMenu {
  width: 100%;
}
.dropdown-item {
  white-space: normal;
  font-family: 'Inter', sans-serif;
  padding-left:15px!important;
}

/*Home page Styling*/
.h3Styling {
  font-family: 'Bebas Neue', cursive;
  font-size:3rem;
  letter-spacing: 0.15px;
  font-weight: 200;
  font-style: normal;
  margin:0;
  padding:0 0 25px 0;  
  text-align: center;
  color:#111111;
}
.text-black {  
  color: #000;
}
.text-white {  
  color: #000;
}
.homepage {
   padding:60px 0 0 0 !important;
  //margin:60px 0 0 0!important;
}
.customSliderSection {  
  // background: rgb(77,0,149);
  // background: linear-gradient(159.39deg, #1B00E9 26.3%, #7900E9 69.56%);
  
}
.customSliderSection img {  
  width: 100%;
  height: 100%;
}
.customSliderSection button {
 
}
.customSliderSection .slideCaption {
  
}
.cntrlsSection {
  position: absolute;  
  top:50%;
  width: 70%;
  max-width: 750px;
  margin: 0 auto;
  left: 50%;
  display: flex;
  flex-direction: column;  
  transform: translate(-50%,-50%);
}
.cntrlsSection h3 { 
  font-family: 'Bebas Neue', cursive;  
  letter-spacing: 1;
  font-weight: 200;
  font-style: normal; 
  // font-size: 4.625rem;
  font-size: 2.625rem;
  text-align: left;
}
.cntrlsCaption, .cntrlsSection p {
  color:#fff;
  font-family: 'Inter', sans-serif;
  font-size: 1.25rem;
}
.ctaButton {
  max-width: 10em;
  background: #fff;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  color: #7900E9;
  border-radius: 40px;
}
.swiperOne .swiper-slide {
  //min-height:640px;
  // max-height: 540px;
  max-height: 400px;
}
.swiperOne .swiper-button-next, .swiperOne .swiper-button-prev {  
  width: 56px;
  height: 56px;  
}
.swiperOne .swiper-button-next {
  right: 20px!important;
  background:url('../icons/arrow-right-rounded-big.svg') no-repeat;
}
.swiperOne .swiper-button-prev {
  left: 20px!important;
  background:url('../icons/arrow-left-rounded-big.svg') no-repeat;
}
.swiperOne .swiper-button-prev:after, 
.swiperOne .swiper-button-next:after {
  content: '';
}


.pageSection {
  margin: 0 0 60px;
}
.courseGlimpse h3 {  
  
}
.overlapImageSize{
  height: 45rem;
}
.swiperTwo {
  position: relative;
  width: 100vw;  
}
.swiperTwo .swiper-wrapper {
  padding-bottom: 45px!important;
}
.swiperTwo > .swiper-slide__content {
  position: absolute;
  top: 0;
}
.swiperTwo .swiper-slide {
  height: auto;
  display: flex;
  // background: #eee;
  // background: transparent;
  align-items: center;
  justify-content: center;
  transition: all 200ms linear;
  transform: scale(0.80);  
  overflow: hidden; 
}
.swiperTwo .swiperSlide.swiper-slide-active {
  // transform: scale(1);
  transform: scale(1) translateY(0);
  // background: #eee;
  // background: transparent;
  color:#fff;
  z-index:999999!important;  
}
.swiperTwo .swiperSlide.swiper-slide-active video {
// width: 100% !important;
// height: 100% !important;
// object-fit: cover !important;
}
.swiperTwo .swiper-slide-next {
  transform: translateX(-150px);
  // transform: translateX(-50px);
  filter: blur(10px);
  // padding: 10%;
  padding: 100px;
}
.swiperTwo .swiper-slide-prev {
  transform: translateX(150px);
  // transform: translateX(50px);
  filter: blur(10px);
  // padding: 10%;
  padding: 100px;

}

.swiperTwo .swiper-slide__content {
  /*height: 300px;*/
}
// .videoSlide video {
//   width:100%;
//   height:auto;
//   filter: blur(5px);
// }
// .swiperTwo .swiperSlide.swiper-slide-active video{
//   filter: none;
// }

.videoSlide video, .videoSlide img {
  width:100%;
  height:auto;
  // filter: blur(5px);
}
.ç .swiperSlide.swiper-slide-active video, .swiperTwo .swiperSlide.swiper-slide-active img {
  filter: none;
} 
.swiperTwo .swiper-pagination {
  margin-top: 20px;
}
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background:url('../icons/bullet-normal.svg') no-repeat;
  opacity: 1;
}
.swiper-pagination-bullet-active {
  width: 8px;
  height: 8px;
  background:url('../icons/bullet-active.svg') no-repeat;
}
.swiperThree .swiper-slide {
  min-height: 380px;
}
.swiperThree {
  min-height: 250px;
}
.swiperThree .swiper-wrapper {
  padding-bottom: 20px;
}
.swiperThree .swiper-button-next, 
.swiperThree .swiper-button-prev {
  border-radius: 50% 50%;
  width: 24px;
  height: 24px;
  /*background: transparent;
  border: solid 1px #7900E9;
  color:#7900E9;*/
  bottom: 3px;
  top:auto;
}
.swiperThree .swiper-button-next {
  right: 20%!important;
  background:url('../icons/arrow-right2.png') no-repeat;
  // background:url('../icons/arrow-right.svg') no-repeat;
}
.swiperThree .swiper-button-prev {
  left: 20%!important;
  background:url('../icons/arrow-left2.png') no-repeat;
  // background:url('../icons/arrow-left.svg') no-repeat;
}
.swiperThree .swiper-button-prev:after, 
.swiperThree .swiper-button-next:after {
  content: '';
}
.swiperThree .swiper-horizontal > .swiper-pagination-bullets, 
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 3px;
  width: 200px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
}
.swiperThree .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background:url('../icons/bullet-normal.svg') no-repeat;
  opacity: 1;
  // background: #92C0E9;
  // border: 1px solid black;
  border: 1px solid #0071CE;
    background: #fff;
}
.swiperThree .swiper-pagination-bullet-active {
  width: 8px;
  height: 8px;
  background:url('../icons/bullet-active.svg') no-repeat;
  background: #0071CE;
}
.masPerfomances {
  background: #ced4da;  
  background-position: center center;
  max-height: 440px;
  width: 100%;
  height: auto; 
  overflow: hidden;
}
.masPerfomances .imageContainer {
  width: 100%;
  margin: 0 auto;
  max-width:1366px;
  max-height: 440px;  
  background: rgba(27,0,233,0.5);  
  background-image: linear-gradient(0deg, #1B00E9 0%, #7900E9 57.69%); /* W3C */
  position: relative;
}
.artistImage {
  opacity: 0.5;
  width: 100%;
  height: auto;
}
.masPerText {
  width: 100%;
  text-align: center;  
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px;
}
.show {
  display: block!important;
}

.preClassCard {  
  min-height:360px;
  overflow: hidden;
  position: relative;
  background: gainsboro;
  padding: 7px;
  // background-image: linear-gradient(0deg, #1B00E9 0%, #7900E9 57.69%);
}
.courseBgImg {
  // opacity: 0.3;
  position: absolute;
  left: 50%;
  top: 50%;
  // top: 0;
  // width: auto;
  // height: 100%;
  width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
}
.navigationCntrlBtns {
  position: relative;
  width: 100%;
}
.slideNext-btn {
  width:24px;
  height:24px;
  right: 40%!important;
  top:-9px;
  background:url('../icons/arrow-right.svg') no-repeat;
  position: absolute;
}
.slidePrev-btn {
  width:24px;
  height:24px;
  left: 40%!important;
  top:-9px;
  background:url('../icons/arrow-left.svg') no-repeat;
  position: absolute;
}


////// 18-10-22 To fixed all same size
// .preClassCard {  
//   min-height:360px;
//   overflow: hidden;
//   position: relative;
//   // background-image: linear-gradient(0deg, #1B00E9 0%, #7900E9 57.69%);
// }
// .courseBgImg {
//   // opacity: 0.3;
//   position: absolute;
//   left: 50%;
//   // top: 25%;
//   // top: 0;
//   // width: auto;
//   // height: 100%;
//   width: 100%;
//   height: auto;
//   transform: translateX(-50%);
// }


//// image size issue 17-10-22
// .courseBgImg {
//   // opacity: 0.3;
//   position: absolute;
//   left: 50%;
//   top: 0;
//   width: auto;
//   height: 100%;
//   transform: translateX(-50%);
// }
.courseDuration {
  padding:5px;
  background: #fff;
  color: #3A4956;  
}
.courseName {
  color:#fff;
}
.preClassCardBody {
  flex-direction: column;
  position: relative;
}
.preClassCardBody .marTpAuto {
  margin-top: auto;
}
.roundedButton {
  border-radius: 40px;
}
.testimonialSec {
  min-height:500px;
  background: #7900E9;
  margin-bottom: 0;
}
.testimonialSec h3 {  
  padding: 50px 0;
}
.swiperFour .swiper-slide {
  min-height:320px;
}
.swiperFour .swiper-button-prev, .swiperFour .swiper-button-next {
  transform: translateY(-50%);
}
.swiperFour .swiper-button-prev {
  /*left:20%!important;*/
}
.swiperFour .swiper-slide {
  width: 450px!important;
}
.swiperFour .swiper-wrapper {
  margin-left:30px;
}
.swiperFour .swiper-button-next, 
.swiperFour .swiper-button-prev {  
  width: 36px;
  height: 36px;
}
.swiperFour .swiper-button-next {  
  background:url('../icons/arrow-right-squared.svg') no-repeat #7900E9;
}
.swiperFour .swiper-button-prev {  
  background:url('../icons/arrow-left-squared.svg') no-repeat #7900E9;
}
.swiperFour .swiper-button-prev:after, 
.swiperFour .swiper-button-next:after {
  content: '';
}
.swiperFour .swiper-button-disabled {
  opacity:1!important;
}
.swiperFour .swiper-button-disabled:after {
  color:#fff!important;
  opacity: 1!important;
}
.testimonialCard {  
  border-radius: 0;  
}
.testimonialCardBody {
  padding: 30px 40px;
}
.testimonialUsrName {  
  font-weight:bold;
  font-size: 1.313em;
  line-height:1em;
  text-align: left;  
}
.coursesEnrolledTxt {
  font-size: 1em;
  color:#A1AEB7;
}
.testimonialFeedback {
  padding: 20px;
}
.testimonialFeedback p {
  font-size: 1em;
  color:#A1AEB7;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.colClass {
  border: none; 
}
.colClass h3 {
  font-size:1.313em;
}
.colClass ul {
  margin:0;
  padding:0;
  list-style: none;
}
.colClass ul li, .colClass p {
  font-size: 0.938em;
  font-family: 'Inter', sans-serif;
  margin-bottom: 10px;
}
.colClass img {
  margin:5px;
}
.colClass i {
  font-size: 16px;
  color: #7900E9;
  margin: 5px;
}
.socialIcons, .aboutMagarisa {
  justify-content: left;
}
.footerRowBg {
  background: #F2F5F7;
  padding: 0;
  margin: 0;
}
.footerRow {
  padding:40px 0;  
}
.vdoWrapper {
  position: relative;
}
.vdoWrapper .audioCntrlIcn {
  width: 44px;
  height: 44px;
  position: absolute;
  bottom: 15px;
  left:15px;
}
.vdoWrapper .audioCntrlIcn.mute {
  background:url('../icons/mute.svg') no-repeat;
}
.vdoWrapper .audioCntrlIcn.unmute {
  background:url('../icons/un-mute.svg') no-repeat;
}
@media screen and (max-width: 1000px) {
  
}
@media screen and (max-width: 768px) {  
  .cntrlsSection h3 {  
    font-size: 3em;
    text-align: left;
  }  
  .cntrlsCaption, .cntrlsSection p {
    font-size: 1em;
  }
  .ctaButton {
    font-size: 16px;
  }
}
@media screen and (max-width: 550px) {
  .cntrlsSection {
    width: 72%;
  }   
  .courseGlimpse {
    padding: 30px 0 0;
  }
  .courseGlimpse h3 {
    
  }
  .cntrlsCaption, .cntrlsSection p {
    display: none;
  }
  .ctaButton {
    font-size: 12px; 
    margin-top:0px!important;   
  }  
  .swiperOne .swiper-slide {
    //min-height: 200px!important;
  }
  .swiperOne .swiper-button-prev, 
  .swiperOne .swiper-button-next {    
    width: 30px!important;
    height: 30px!important;
    border: none;    
    background-size: cover;
  }
  .swiperOne .swiper-button-next {
    right: 10px!important;
  }
  .swiperOne .swiper-button-prev {
    left: 10px!important;
  }
  .swiperOne .swiper-button-prev:after, 
  .swiperOne .swiper-button-next:after {
    font-size:16px!important;
  }
  .swiperTwo {
    min-height:240px;
  }
  .swiperTwo .swiper-slide {
    height:200px!important;
  }
  .testimonialSec {
    min-height:300px;
  }
  .testimonialSec h3 {
    padding: 30px 0;
  }
  .testimonialFeedback p {    
    -webkit-line-clamp: initial;
  }
  .swiperFour .swiper-slide {
    min-height:420px;
  }
  .swiperFour .swiper-wrapper {
    margin-left:0;
  }
  .swiperFour .swiper-slide {
    width:100%!important;
  }
  .swiperFour .swiper-button-disabled {
    display: none;
  }
  .preClassCard {  
    // min-height:400px;
    height: auto;
  }
  .pageSection {
    margin: 0;
  }
  .footerRow {
    flex-direction: column;
  }
  .colClass {
    text-align: center;    
    padding: 10px 0;
    
  }
  .socialIcons, .aboutMagarisa {
    justify-content: center;
  }
}
@media screen and (min-width: 1024px) {
  .swiperThree .swiper-button-next {
    // right: 35%!important;
    right: 42%!important;
  }
  .swiperThree .swiper-button-prev {
    // left: 35%!important;
    left: 42%!important;
  }
}
@media screen and (min-width: 451px) and (max-width: 600px) {
  // .vjs-theme .vjs-custom-forward-button {
  //   right: 50%;
  //   transform: translate(100px, -55%);
  // }
  // .vjs-theme .vjs-custom-backward-button {
  //   left: 50%;
  //   transform: translate(-100px, -55%);
  // }
}
@media screen and (max-width: 450px) {
  // .vjs-theme .vjs-custom-forward-button {
  //   right: 50%;
  //   transform: translate(75px, -55%);
  // }
  // .vjs-theme .vjs-custom-backward-button {
  //   left: 50%;
  //   transform: translate(-75px, -55%);
  // }
  .vjs-theme .vjs-custom-multiangle-button, 
  .vjs-theme .studioModeBtnspanTxt, 
  .vjs-theme .fscreenBtnspanTxt {
    display: none!important;
  }
  .vjs-theme .baseBarIconWrapperWidthAuto {
    margin-right:6px;
  }
  .vjs-theme .vjs-title-bar {
    font-size:1.5em;
  }
  .vjs-theme .vjs-custom-forward-button, 
  .vjs-theme .vjs-custom-backward-button, 
  .vjs-theme .vjs-custom-play-button, 
  .vjs-theme .vjs-custom-pause-button,
  .vjs-theme .vjs-custom-nextvideo-button,
  .vjs-theme .vjs-custom-prevideo-button  {
    width:40px;
    height:40px;
  }  
  .ant-drawer-content-wrapper {
    width:100%!important;
  }
  .ant-drawer-body {
    padding:0;
  }
  .ant-drawer-body  .list-group-item {
    font-size:1em;
    border:none;
    padding: 0.75rem 0.25rem;
  }

  .navigationCntrlBtns .slidePrev-btn {
    left: 10%!important;
  }
  .navigationCntrlBtns .slideNext-btn {
    right: 10%!important;
  }
  .supportCol {
    justify-content: center!important;
  } 
}
._1YOWG {
  width: 100%;  
}
._1YOWG p {
  width: 90%;
  margin:0 auto;
  text-align: center;
}
.supportCol {
  justify-content:flex-start;
}
/*Dec 23*/
.swiper-cf > .swiper-wrapper {
  display: flex!important;
  align-items: center!important;
  height:460px!important;
}
.baseSwiperStyle {
  cursor: pointer;
  /*height: 440px;*/
  // background: #4d0095;  
  background: linear-gradient(159.39deg, #1B00E9 26.3%, #7900E9 69.56%);  
}
.swiperImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /*height: 440px;*/
  max-width: 1366px;
  margin:0 auto;
}
.OverLayContainer {
  width:100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.32);
}
.swiperImageContainer img {  
  width: auto;
  height: 100%;
  object-fit: cover;
  mix-blend-mode: lighten;
  opacity: 0.6;
}
ol.breadcrumb {
  padding:0;
  margin: 0px 0;
}
.courseTitleH1 {
  margin:0;
  padding:0;
  padding-bottom: 28px;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  letter-spacing: -0.25px;
  color: #3A4956;
}
.breadcrumb-item > a {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color:#003399!important;
  // color:#7900E9!important;
}
.breadcrumb-item:last-child > a {
  color: #003399!important;
  // cursor: none!important;
}

.artistPageBd .breadcrumb-item > a, 
.artistPageBd .breadcrumb-item + .breadcrumb-item::before {
  color: #fff!important;  
}


.artistPageBd .breadcrumb-item + .breadcrumb-item::before {
  background:url('../icons/breadcrumb-icon-white.svg') no-repeat;
}


.breadcrumb-item + .breadcrumb-item::before {
  content: " ";
  // var(--bs-breadcrumb-divider, ">");
  background:url('../icons/breadcrumb-icon.svg') no-repeat;
  width:6px;
  height:10px;
  display: flex;
  margin-top: 7px;
  margin-right: 5px;
}
.swipercf {
  position: relative;
  margin-bottom: 20px;
  /*background: #717171;*/
  padding:15px 0;  
}
.swipercf .swiper-slide {
  // height: 470px;
  // /*height: 470px;*/
  width: 300px;
  display: flex;
  // background: #eee;
  // background: transparent;
  align-items: center;
  justify-content: center;
  transition: all 200ms linear;
  transform: scale(1);  
  overflow: hidden;
  padding: 10px; 
  min-height: 310px;
}
.swipercf .swiperSlide.swiper-slide-active {
  // transform: scale(1);
  transform: scale(1.3) translateY(0);
  // background: #eee;
  // background: transparent;
  color:#fff;
  z-index:999999!important;
}
.swipercf .swiperSlide.swiper-slide-active img {  
  box-shadow: 0px 0px 10px #000000;  
}
.swipercf .swiperSlide img {
  
}
.swipercf .swiper-slide-next {
  //transform: translateX(-150px);  
  //filter: blur(10px);
}
.swipercf .swiper-slide-prev {
  //transform: translateX(150px);  
  //filter: blur(10px);  
}

.swiper-horizontal > .swiper-pagination-bullets, 
.swiper-pagination-bullets.swiper-pagination-horizontal {
  margin: 0 auto;  
  overflow: hidden;
  transform: translateX(-50%);
  text-align: center;
  width: auto;
}

.customPagination {  
  transform: translateX(0%)!important;
}

.swiper-pagination-bullet {
  text-indent: -9999px;
}

.paragraphTxt {
  font-size:1.5rem;  
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;
}

.loginBoxWrapper {
  margin-top:30px;  
}
.loginMobileBox {
  background: #f1f1f1;
  padding: 40px 30px 50px;
  border-radius: 5px;
}
.otpInput {
  width: 62px;
  height: 48px;
}
.loginInputForm {
  display: flex;  
  align-items: center;
  border: solid 1px #848484;
  border-radius: 8px;  
  padding: 5px;
  background:#fff;
  justify-content: start;
}
.loginInputForm .mb-3 {
  width:100%;
}
.selectComp {  
  width: auto;
  background: none;
  background-image: none;
  border: none;
  border-right: solid 1px #ccc;
  border-radius: 0;
  padding: 5px;
  font-size:18px;
  font-family: 'Inter' sans-serif;
  color: #383838;
  opacity: 0.6;
  text-align: center;
}
.inputComp {  
  margin: auto;
  background: none;
  border:none;
  font-size:15px;
  color: #000;
  opacity: 1;
  margin-bottom: 0;
  padding: 5px 5px 5px 10px;
  font-family: 'Inter' sans-serif;
  font-weight:500;
}
.inputComp::placeholder {
  color: rgba(56, 56, 56, 1);
  opacity: 0.4;
  font-weight:400;
}
.loginInputForm .mb-3 {
  margin-bottom: 0!important;
}
.loginInputForm .invalid-feedback {
  margin-left: 10px;
}
.otpBox div {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.otpBox div input {
  max-width:62px;
  height: auto;
}
.loginBoxWrapper .form-label {
  
}
.filterRadioGroup button {  
  /*border-radius: none;
  border:none;
  border-right:solid 1px #505D68;*/
}

.labelStyle, .loginBoxWrapper .form-label {
  font-family: 'Inter', sans-serif!important;
  font-size: 0.875rem;
  font-weight: 500;
  // line-height: 14px;
  letter-spacing: 1.5px;
  text-align: left;
  color: #5E5E5E;
}

.dividerWrapper {
  width:325px;
  margin: 0 auto;
}

.dividerLoginWith {
  padding:10px;
  background: rgba(235, 235, 235, 1);
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 1.25px;
  text-align: left;
  color: rgba(94, 94, 94, 1);
}

.ant-divider-horizontal.ant-divider-with-text {
  border-top-color: rgba(80, 93, 104, 0.3)
}

.ant-divider-inner-text {
  padding:0;  
}
.ant-radio {
  top: 0.4em;
}

.ant-radio-inner {
  width:22px;
  height:22px;
}
.ant-radio-inner::after {
  width:22px;
  height:22px;
  margin-top: -11px;
  margin-left: -11px;
}

.smallBoxWrap {
  width: 24px;
  height: 24px;
  background: #C4C4C4;
  margin-right: 6px;
  border-radius: 5px;
  margin-top: 2px;
}
.courseFilterItem {
  width:"80px";    
  height:"75px";
  text-align: center;
  margin: 0 auto;
}

.borNone {
  border:none;
}

label.resendOTPBx::after {
  width:100%;
  height:1px;
  background: rgba(94, 94, 94, 1);
}

.line {             
  /* Increase this as per requirement */
  padding-bottom: 1.5px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  width: fit-content;
}

.usrFrmFlds {    
  color: rgba(56, 56, 56, 1);
  opacity: 1;  
  font-size:18px;
}
.usrFrmFlds:disabled, .usrFrmFlds[readonly] {
  color: rgba(56, 56, 56, 0.5);
}
.usrFrmFlds::placeholder {
  color: rgba(56, 56, 56, 0.4);
}
.inputComp::placeholder {
  color: rgba(56, 56, 56, 0.6);
}

@media screen and (max-width: 1366px) {
  .swiperOne  .swiper-slide {
    //min-height: 500px;
  }
}

@media screen and (max-width: 360px) {
  .swiperOne  .swiper-slide {
    //min-height: auto;
  }
  h3.fontInter {
    font-size: 28px!important;
  }
  .courseFilterItem {    
    height:"50px";
  }
}

.inputComp .fontInter .is-touched .is-dirty .av-valid .form-control {
  background: transparent!important;
  color:#000;
}
.noti-icon {
  margin-top:4px;
}
.header-item {
  height: 70px;
}
.clrOutlined {
  background:transparent !important;
  border: solid 2px #5E5E5E;  
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  padding:18.5px 56px;
  color: #5E5E5E!important;  
  border-radius: 5px !important;
}
.customAntselectArrow > svg > path {
  fill: #2f80ed!important;
}
.editProfileDwr .ant-drawer-title {
  font-family: 'Inter'!important;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;  
  color: #505D68;  
}
.editProfileDwr .ant-drawer-header {
  padding: 16px 40px 10px;
}

.editProfileDwr .ant-drawer-body {
  padding: 0 40px;
}
.feedBackDwr .ant-drawer-body {
  padding: 0 30px;
}

.feedBackDwr .ant-drawer-title {
  font-family: 'Quicksand'!important;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 26px;
  color: #505D68;
  text-transform: initial;
}
.feedBackDwr .feedbackTypeLabel {
  font-family: 'Inter'!important;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;  
  color: #383838;
  text-transform: capitalize;
}
.feedBackDwr .feedBackTxt {
  font-family: 'Quicksand'!important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;    
  color: #383838!important;
}
.feedBackDwr .feedBackTxt span {
  font-size: 16px;
}
.feedBackDwr .dropzone {
  min-height: 166px;
}
.feedBackDwr .needsclick {
  padding:20px;
}
.hvntTxt {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #505D68;
}
.prefFooter .shadow_footer{
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  margin: 0;
}
.profilePic {
  width:120px;
  height:120px;
}
.notifyTxt {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #5E5E5E;
}
.letterSpacingNone {
  letter-spacing: 0px!important;
} 
.disabledFormInput {
  background-color:transparent!important;
}
.prefH4 {    
  margin-bottom: 14px;
}
.card-title-desc {
  margin-top:20px!important;
  margin-bottom:10px!important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #5E5E5E;
}
.avFieldLabel label.form-label {
  font-family: 'Inter', sans-serif!important;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 1.5px;
  text-align: left;
  color: #5E5E5E;
}


.courseInprogressH3 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 31px;
  color: #A7A8A9;
  // color: #5E5E5E;
}

.crSwiper {
  margin-bottom: 20px;
}

.ntLoggedinContentColumnWrapper .contentColumn {
  width:1126px!important;
}
#sidebar-menu {
  padding: 0px 0 30px 0;
}
#sidebar-menu ul li.list-group-item ul li {
  border:none;
}
#sidebar-menu ul li.list-group-item {
  margin-bottom:15px;
}
#sidebar-menu .has-arrow:after {
  margin-right: 8px!important;
}
.pdfIconDark {
  width:24px;
  height:24px;
  background: url('../icons/pdf-icon-dark.svg') no-repeat;
  margin-right:5px;
}
.progressContainer {
  padding:15px 20px;
  border-radius: 5px;
  background: #F8F8F8;
}
.progressLabel {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #383838;
}
.ant-progress-inner {
  background: #E4E4E4;
}
.imgWrapBox {
  margin-left: 27px;
}
.ntLoggedinContentColumnWrapper .imgWrapBox {
  margin-left: 0;
}
@media only screen and (min-width: 1600px) {
  .imgWrapBox {
    margin-left: 0;
  }
}
@media only screen and (max-width: 1365px) {
  .imgWrapBox {
    margin-left: 0;
  }
}
.artistBgWrapper {
  margin-top:60px;
}
.artistImageWrapper{
  width:153px;
  height:180px;
  background: #ccc;
  border-radius: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.artistCourseCardWrapper {
  display: grid;
  grid-template-columns: 361px auto 361px;
  gap:20px;
  position: relative;    
}
.artistCourseCard {
  border: 1px solid #ECECEC; 
  border-radius: 10px; 
  height: 112px; 
  //width: 361px;
  /*margin-bottom: 20px;
  margin-right:21px;*/
}
.artistCourseCardDiv {
  position: relative;
}
.artistCourseCard .ant-card-body {
  padding:15px;
}
.artistCourseCard:nth-child(3n) {
  margin-right: 0;
}
.artistDetails {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;    
  color: #5E5E5E;
}

.crownIcon {
  width:24px;
  height:24px;
  background: url('../icons/crown.svg') no-repeat;
  position: absolute;
  top: 10px;
  left: 70px;
}
.infoIcon {
  width:24px;
  height:24px;
  background: url('../icons/info.svg') no-repeat;
  position: absolute;
  top: 6px;
  right: 6px;
}
.accordionTitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #5E5E5E;
}
.accordionTitle.active {
  color: #0071CE!important;
  // color: #7900E9!important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #5e5e5e;
  border-color: #5e5e5e;
}
.ant-checkbox-inner {
  top: 3px;
  width: 24px;
  height: 24px;
}
.ant-checkbox-checked::after {  
  top: 3px;  
  border: 1px solid #5e5e5e;
}
.ant-checkbox-inner::after {  
  top: 48%;
  left: 27.5%;
}
.labelTxt {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #5E5E5E;
}



/**/
/*Jan 06, 2023*/
.navbar-header {
  height: 60px;
  padding: 0 48px;
}
.marginTop60 {
  //border: solid 1px #000;
  //min-height:500px;
  margin-top:60px;
}
.pageWrapperBox {
  margin: 0 auto;
  max-width: 1366px;  
  //border: solid 1px #000;
  padding:60px 120px;  
}

.swiper .video-js .vjs-tech {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;  
  height: auto;
  transform: translate(-50%,-50%);
}

@media only screen and (max-width: 640px) {
  .navbar-header {    
    padding: 0 10px;
  }
  .swiperOne .swiper-slide {
    //min-height: 200px!important;
  }
  .h3Styling, .cntrlsSection h3, .cntrlsSection .h3 {
    font-size: 1rem;
  }
}


/*Jan 07, 2023 - Custom Styling*/

.header-title {
  font-size: 3.75rem;
  font-weight: 500;  
  letter-spacing: -1px;
  margin-bottom: 20px;
}
.header-subtitle {
  font-size: 2rem;
  font-weight: 500; 
  color:#383838;  
}
.bodytext-large {
  font-size: 1.5rem;
  font-weight: 400; 
}
.bodytext-normal {
  font-size: 1rem;
}
.bodytext-small {
  font-size: 0.875rem;
}
.bodytext-x-small {
  font-size: 0.75rem;
}
.loginBulletPoints {
  margin-top:40px;
}
.socialPrefBtn {
  max-width:270px;
}


/*Dashboard*/
// .react-multiple-carousel__arrow--left {
//   display: none;
// }
// .react-multiple-carousel__arrow--right {
//   display: none;
// }
// .carousel-container_{
//   padding-bottom:40px;
// }
.react-multi-carousel {
  //margin-top:3rem;
}
// .react-multi-carousel-dot-list {  
//   flex-wrap: wrap;
//   width: 86%;
//   margin: 0 auto;
// }


/*Preferences*/
.prefPageContent {
  margin-top: -45px!important;
  padding:0 0 10px 0; 
}
.usrProfileSec {
  background: #EBEBEB;
  height: 213px;
  margin-top: 25px;
}
.prefContainer {
  background: #F9F9F9;
  padding: 30px 30px 60px;
  border-top: solid 1px #C2D1D9;
  border-right: solid 1px #C2D1D9;
  border-bottom: solid 1px #C2D1D9;
  border-left: none;
  border-radius: 0px 10px 10px 0px;
}
.prefMnuWrapper {
  border-radius: 10px 0px 0px 10px;
  border-top: solid 1px #C2D1D9;
  border-right: none;
  border-bottom: solid 1px #C2D1D9;
  border-left: solid 1px #C2D1D9;
  padding-right: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.prefMnu .active_tab {
  background: #F9F9F9;
  border:none;
}
.usrPrefContainer {
  margin: -80px auto 0; 
  max-width:1146px;
}
.langRadioGrp .ant-radio-wrapper {
  font-family: 'Inter';  
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color:#5E5E5E;
}
.langRadioGrp .pay_cards {
  padding:20px 24px;
  margin:0;
  border: 0.5px solid rgba(132, 132, 132, 0.5);
}
.langRadioGrp .ant-space {
  //gap:20px!important;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));  
  gap:20px!important;
}
.prefMnu {
  flex-direction: column;
}
.prefMnu .navLink {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;   
  color: #505D68;  
  margin:1rem 0;
}
.rowWrapper {  
  padding: 0 15px;
}
.secondaryTab {
  margin-top:20px;
}
.usrSection {
  padding:20px 10px 10px 0;
}


/*index*/
// .swiper .swiper-wrapper .swiper-slide {
//   min-height: 140px;
// }

/*Footer*/
.supportColumn {
  display:flex;
  //align-items: flex-end;
}

.slide-image {
  width: 80% !important;
  object-fit: cover;
}

/*Course Pages*/
.marginTop60 {
  margin-top:60px;
}
.paddingTop60 {
  padding-top:60px;
}
.pageWrapperBoxFluid {  
  max-width: 100%;
  //border: solid 1px #000;
  background: #fff;  
}
.ntLoggedinContentColumnWrapper.col {
  padding:0;
  border: none;
}
.contentColumnWrapper.col {
  border: none;
}
.ntLoggedinContentColumnWrapper .contentColumnDiv {
  max-width:1126px;
  margin:0 auto;
}
.contentColumnWrapper .contentColumnDiv {
  max-width:846px;  
}  
.courseDescription {
  padding-right: 10px;
}
.pageDivWrapper {
  margin: 0;
  padding: 0;
}

.courseSwiper {  
  width: 100%;
  height: 470px;
  margin-bottom: 20px;
}

.courseSwiper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: rgba(58, 73, 86, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.courseSwiper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.assignmentBtnWrapper {  
  padding:15px;
  background: rgba(242, 245, 247, 0.5);  
  border-radius: 16px;
  border: solid 1px #979797;
}

.assignMentPageH1 {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 35px;
  letter-spacing: -0.25px;
  color: #3A4956;
}
/*20 Jan 2023*/
.ivsPlayerContainer {
  margin-bottom: 20px;
}
.baseSwiperBanner1, .baseSwiperBanner2 {
  margin: 0;
  padding: 0;
}
.baseSwiperBanner1 .h3Styling {
  padding: 0;
  margin: 0;
}
.cntrlsSectionPara {
  max-width: 740px;
}

/*23-01-2023*/
.imgCircle {  
  background: #E4E4E4;
  background-image: url('../icons/img-loader.svg');
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  width:250px;
  height:250px;
  margin: 0 auto 25px;
}
.moonLoaderWrapper {
  width:100%; 
  height:100vh; 
  top:0; 
  left:0; 
  overflow:hidden;
  z-index:999999;
  position: absolute;
  background:rgba(0,0,0,0.6);
}
.moonLoaderDiv {
  position: absolute;
  top:50%;
  left:50%; 
  transform:translate(-50%,-50%);
  width:352px;
}
.loaderText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
  color: #F8F8F8;
}

/*Jan 26, 2023*/
.certificateContentColumnDivWrapper {
  width:100%;
  max-width: 1125px;
  margin:0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  min-height: 708px;
}
.certificateContentColumnDiv {
  text-align: center;
}  
.certificateContentColumnDiv h1 {
  text-align: center;
  padding: 25px;  
  font-style: normal;
  font-weight: 700;
  font-size: 1.75rem;
  text-align: center;
  letter-spacing: -0.25px;
  color: #111111;
}
.certificateContentColumnDiv img {
  margin: 0 auto;
}
.fontQS {
  font-family: 'Quicksand', sans-serif!important;
}
.certificateContentColumnDiv p {  
  font-style: normal;
  font-weight: 700;
  font-size: 1.313rem;  
  text-align: center;
  color: #3A4956;
  max-width: 718px;
}
.certificateContentColumnDiv p.small {
  font-size: 0.875rem; 
}
.powrdBy {
  margin-top: 35px;
}
.downloadCertificateButton {  
  background-image: linear-gradient(to bottom, #D70E37 0%, #7900E9 100%);
  height:58px;
  color:#fff;
  border:none;
  border-radius: 8px;  
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;  
  letter-spacing: 0.75px;
  text-transform: uppercase;
  padding: 15px 10px;
  min-width: 285px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.studentPercentageDiv {
  padding: 16px;
  max-width: 754px;
  background: #F2F5F7;
  margin: 0 auto;
  border-radius: 10px;  
}
.studentPercentageDiv p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 0.938rem;  
  text-align: center;
  color: #3A4956;
  margin: 0;
}

/*Feb 1, 2023*/
.anticon {
  margin-left:3px;
}
.cntrlsSection h3, .cntrlsSection .h3 {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;  
  letter-spacing: 0.15px;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.45);
}
.cntrlsCaption, .cntrlsSection p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;  
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.swipercf .swiper-slide-prev {  
  opacity: 0.6;  
  margin-top: 10px;
  // filter: blur(3px);  
}
.swipercf .swiper-slide-next {  
  opacity:0.6;  
  margin-top: 10px;
  // filter: blur(3px);  
}
.swiper-pagination-bullet {
  margin: 0 8px!important;
}
.pricingSection {
  margin: 60px 0 0 !important;
  background: #fff;
  padding: 60px 0;
}
.pricingSection .paragraphTxt {
  font-family: 'Inter';
  font-style: normal;
  font-size:1.5rem;
  font-weight: 400;  
  text-align: center;
  color: #3A4956;
}
.testimonialFeedback {
  padding-left: 0;
}
.testimonialFeedback p {
  font-family: 'Quicksand', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;  
  color: #A1AEB7;
}
.coursesEnrolledTxt {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;  
  color: #A1AEB7;
}
.supportColumn {
  display:flex;
  align-items: flex-start;
}
.supportColumnFlexEnd {
  display:flex;
  align-items: flex-end;
  padding-bottom: 15px;
}
.colClass h3 {
  margin-bottom: 15px;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 1.313rem;
  color: #3A4956;
}
.footerRow .colClass ul li, .footerRow .colClass p {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 0.938rem;  
  color: rgb(58, 73, 86);
}
.copyRightCls {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 0.938rem;
  color: #A1AEB7;
}
.loginlabeltext {
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 1.25rem;
line-height: 140%;
color: #5E5E5E;

}
.logincntrlbtn {
  // border-radius: 30px;
  border-radius: 50px;
  font-size: 0.938rem; 
  height: 52px;
  background: #5E5E5E;
  color: white;
  letter-spacing: 1.25px;
  padding: 15px 0px
}
.logincntrlbtn:hover{  
  color: white; 
}
.css-26l3qy-menu {
  width: 300px !important;
  text-overflow: ellipsis !important; 
}
.registerNowBtn {
width: 300px;
height: 52px;/* White */
background: #FFFFFF !important;
box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.45);
border-radius: 40px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 0.938rem;
text-align: center;
letter-spacing: 1.25px;
text-transform: uppercase;
color: #7900E9 !important;
margin-bottom: 10px;
}
.registerNowTxt {
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 1.5rem;
color: #FFFFFF;
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.landingSpclCourse {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 1.313rem;  
  color: #3A4956;
  margin-top: 50px;
  margin-left:10px;
}

/*dashboard*/
.courseCardTitle {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 1.313rem;
  line-height: 1.625rem;
  color: #3A4956;  
}
.courseCardText {
  /* Body 2 */
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1rem;
  color: #3A4956;
}
.antCourseCard {
  width: 266.18px;
  height: 358.45px;
}
.antCourseCard .ant-card-body {
  height: 186px;
  max-height: 186px;
}

/*3 Feb 2023*/
.courseTitleH1 {
  padding-bottom:18px;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 35px;
  letter-spacing: -0.25px;
  color: #3A4956;
}
.courseDescription p, .courseDescription p span {
  font-size: 0.938rem!important;
}
.metrics h1 {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 1.75rem;    
  letter-spacing: -0.25px;
  margin: 0 0 5px 0;
  padding: 0;
}
.courseRegisternowBtn {
padding: 15px 10px;
width: 230px;
height: 58px;
// background: linear-gradient(359.36deg, #7901E9 0.31%, #D70E37 118.01%);
background: linear-gradient(to right, #003399, #10CFC9);
border-radius: 8px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 1.25rem;
text-align: center;
letter-spacing: 0.75px;
text-transform: uppercase;
color: #F2F5F7;
border: none;
}
.artistCourseCard {
  border: 1px solid #A1AEB7;
  height: auto;
}
.masterPageBg {
  background-image: linear-gradient(to bottom, #6734ED 0%, #9433ED 100%);
  height: 265px;
}

///3-2-23 Jose
.menuItemclass {
  padding: 10px;
  border-bottom: solid 1px #ccc;
}
.menuItemclass:last-child{
  border-bottom: none;
}
.menuRapper {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
}
.antSpecialCourseCard {
  width: 266.18px;
  height: auto;
}

.css-26l3qy-menu {
  width: 300px !important;
  // text-overflow: ellipsis !important; 
}

.react-select__option {
  padding: 10px;
  border-bottom: solid 1px #ccc;
  background: #fff !important;
  color: #000 !important;
}
.swiperVideoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 570px;
  max-width: 1366px;
  margin:0 auto;
}

.vjs-promotional-theme {
  height: 570px;
  width: 1126px;
}
.vjs-promotional-theme video {
  height: 100% !important;
  width: auto !important;
}



///4-2-23
/*Dashboard Course Card*/
.dashboardCourseCard {  
  margin:0 10px 30px;
  position: relative;
}
.h4Wrapper {  
  height: 54px;    
  overflow: hidden;   
}
.h4Wrapper h4 {
  margin: 0;
  padding: 0;     
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 1.313rem;  
  color: #3A4956;   
}
.dashboardCourseCardText {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 16px;
  color: #3A4956;
  height: 33px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboardCourseCard .ant-card-body {
  // height: 164px;
  // max-height: 164px;
  // height: 180px;
  // max-height: 180px;

  // height: 245px;
  // max-height: 250px;
}

.ant-card {
  box-sizing: border-box;
  margin: 10px;
  margin-bottom: 40px;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  border-radius: 2px;
}

.artistInfooncard {
  position: absolute;
  bottom: 0;
}
.splCourseCard {
  margin:0 10px 30px;
  position: relative;  
  //min-width: 266px!important;  
}
.splCourseCard .ant-card-cover img {  
  /*opacity: 0.5;
  mix-blend-mode: lighten;*/
}
.splCourseCard .h4Wrapper {
  padding: 10px 0 0;
  height: 60px;
}
.splCourseCard .h4Wrapper h4 {
  color:#fff;  
}
.splCourseCardArtistInfo {  
  position: relative;  
}
.splCourseCardImageWrapper {  
  height: 244px;     
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(17, 17, 17, 0.85) 60%);
  overflow: hidden;
}
.splCourseCardImageWrapper img {
  object-fit:contain;    
  mix-blend-mode: multiply;
  opacity: 0.3;
  width: 100%;
}
.splcrsdatacontainer {
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.avatar-group-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;  
}
.avatar-group-item a {
  margin-left:-5px;
}
.avatar-group-item a:first-child {
  margin-left:0px!important;
}
.avatar-group-item a img {
  border: solid 1px #fff;
}
.splCourseCard  .ant-card-body {
  background: #fff;
}
.splCourseCard .ant-card-body {
  height: 84px;
  max-height: 84px;
}
.splCrsTxt {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 0.938rem;  
  line-height: 12px;
  color: #F2F5F7;
  margin-left: 8px;
}
.ant-dropdown-menu-submenu-popup ul {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-dropdown-menu-submenu {
  padding: 10px;
  border-bottom: solid 1px #ccc;
}
.ant-dropdown-menu-submenu:last-child{
  border-bottom: none;
}

/*4feb*/
.testimonialCardBody {
  padding: 57px 69px 58px 62px;
}
.testimonialSec {
  padding-bottom: 62px;
}
.cntrlsSectionPara {
  max-width:504px;
}
.abtpleft {
  max-width: 543px;
}

.ant-select-arrow {
width: 24px;
color: #2F80ED;
height: 24px;
font-size: 15px;

}


.graphCanvas {
  position: relative;
  bottom: 0;
  transform: rotate(180deg);
}

.cancelButton{
  margin-right: -10rem !important;
}

// .graph-canvas {
//   // position: relative;
//   top: 50px;
//   left: 20px;
// }





.expand-card {
  display: flex;
  height: 40rem;
  gap: 1rem;
}

.expand-card>img {
  flex: 1;
  border-radius: 1rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  transition: all .8s cubic-bezier(.25, .4, .45, 1.4);
  background-color: #000;
}

.expand-card>img:hover {
  flex: 5;
}



/////6-10-23

.courseInfoSwiper {  
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.courseInfoSwiper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: rgba(58, 73, 86, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.courseInfoSwiper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.centered-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
 }


 .textOverFlowEllipsisHeaderss {
  display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
text-transform: capitalize;
font-family: 'poppins' !important;
font-weight: 500 !important;
}

.dashboardCourseCardTextss{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  font-family: 'poppins' !important;
  color: #A7A8A9;
}

.footerss{
  font-family: 'poppins' !important;
}


.mydashboardCourseCard {  
  margin:0 10px 30px;
  position: relative;
}

.mydashboardCourseCardTextss{
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  font-family: 'poppins' !important;
  color: #A7A8A9;
}
.mydashboardCourseCard .ant-card-body {

  height: 200px;
  max-height: 200px;
}

.bg-transition{
  height: 250px;
  background-repeat: no-repeat;
  background-origin: border-box;
  background-position: right;
  background-size: cover;
  height: 250px;
  border-radius: 30px

}
.bg-transition:before {
  content: '';
  transition: all 0.7s;
  background-repeat: no-repeat;
  background-origin: border-box;
  background-position: right;
  background-size: cover;
  height: 250px;
  border-radius: 30px;
  opacity: 1;
}

.bg-transition:hover:before{
  opacity: 0.5;
}

.offcanvas.offcanvas-end {
  width:600px !important
}

.table > :not(caption) > * > * {
  background-color: transparent !important;
}


.accordion-button.collapsed::after {
  color:#fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after {
  color:#fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}


/////payment loader 10-1-24
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.loader {
  display: inline-block;
  width: 60px;
  height: 60px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.dropdown-menu-end-share {
  min-width: 120px !important;
}


.hovereffect {
  width:100%;
  height:100%;
  float:left;
  overflow:hidden;
  position:relative;
  text-align:center;
  cursor:default;
  }
  
  .hovereffect .overlay {
  width:100%;
  height:100%;
  position:absolute;
  overflow:hidden;
  top:0;
  left:0;
  opacity:0;
  background-color:rgba(0,0,0,0.5);
  -webkit-transition:all .4s ease-in-out;
  transition:all .4s ease-in-out
  }
  
  .hovereffect img {
  display:block;
  position:relative;
  -webkit-transition:all .4s linear;
  transition:all .4s linear;
  }
  
  .hovereffect h2 {
  text-transform:uppercase;
  color:#fff;
  text-align:center;
  position:relative;
  font-size:17px;
  background:rgba(0,0,0,0.6);
  -webkit-transform:translatey(-100px);
  -ms-transform:translatey(-100px);
  transform:translatey(-100px);
  -webkit-transition:all .2s ease-in-out;
  transition:all .2s ease-in-out;
  padding:10px;
  }
  
  .hovereffect a.info {
  text-decoration:none;
  display:inline-block;
  text-transform:uppercase;
  color:#fff;
  border:1px solid #fff;
  background-color:transparent;
  opacity:0;
  filter:alpha(opacity=0);
  -webkit-transition:all .2s ease-in-out;
  transition:all .2s ease-in-out;
  margin:50px 0 0;
  padding:7px 14px;
  }
  
  .hovereffect a.info:hover {
  box-shadow:0 0 5px #fff;
  }
  
  .hovereffect:hover img {
  -ms-transform:scale(1.2);
  -webkit-transform:scale(1.2);
  transform:scale(1.2);
  }
  
  .hovereffect:hover .overlay {
  opacity:1;
  filter:alpha(opacity=100);
  }
  
  .hovereffect:hover h2,.hovereffect:hover a.info {
  opacity:1;
  filter:alpha(opacity=100);
  -ms-transform:translatey(0);
  -webkit-transform:translatey(0);
  transform:translatey(0);
  }
  
  .hovereffect:hover a.info {
  -webkit-transition-delay:.2s;
  transition-delay:.2s;
  }




 

