.menuAccordian{
    border-bottom: none;
    // border-radius: 12px;
    margin: 5px;
}
.menuTotalBorder{
    border: none;
    border-radius: 12px;
    margin: 5px 0;
    // box-shadow: -1px 8px 10px 0px #ababab94;
}

.menuStyle{
    padding: 5px;
    cursor: pointer;
}

._1ceqH ._1lB9c {
    display: none;
}

._1ceqH ._2fG9h {
    width: 100%;
    margin: 0 auto;
    padding: 15px 0;
}

._1ceqH ._dt3-T {
    min-height: 210px;
    background: #212121;
    border-radius: 0 0 3px 3px;
    display: flex;
    flex-direction: column;
    border: 1px solid #979797;
}
._1ceqH ._3bC73 {
    position: relative;
    flex: 1 1;
    background: #F1F1F1 !important;
}
._1ceqH ._1dpop {
    width: 60px;
    display: flex;
    height: 60px;
    position: fixed;
    justify-content: center;
    align-items: center;
    transition: .5s ease all;
    background: #979797;
    border-radius: 50%;
    bottom: 65px;
    right: 20%;
    color: #fff;
    font-size: 25px;
}
._1ceqH ._1YOWG ._eV_dK {
    color: black;
}
._1ceqH ._f2DT8 >span {
    text-align: center;
    color: black;
}
._1ceqH ._1Yplu{
    text-align: center;
    display: none;
}
.orBG> span{
    background: #f1f1f1;
    border-radius: 113px;
    padding: 10px 11px;
}

.animatedBar > div{
    justify-content: center;
}

#angle_dropdown{
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #303033 !important;
    background-position-x: 244px !important;
    }
    
.breadcrumb {
    padding-left: 0!important;
}
.topLevelListItem {
    padding: 0.625rem 0!important;
}
#sidebar-menu .has-arrow:after {
    font-size: 1.5rem!important;
    margin-right: 6px!important;
}
.list-group-item span {
    cursor:pointer;
}
.list-group-item span:hover {
    color:#2534b6;
}

.fullWidthImageContainer {
    max-width:1126px;
    margin: 0 auto;
    text-align: center;
    height: 470px;
}
.fullWidthMediaContainer {
    max-width:1126px;
    margin: 0 auto;
    text-align: center;
}
.fullWidthMediaContainer img, .fullWidthMediaContainer video { 
    height: 100%;
    width: auto;
}
.fullWidthImageContainer img {
    width: auto;
    height: 470px;
}
.fullWidthVideoContainer {
    max-width: 1126px;
    max-height: 470px;
    margin: 0 auto;
}
.vjs-crs-theme, .vjs-promotional-theme {
    height: 570px;
    width: 1126px;
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
    .fullWidthImageContainer {
        max-width: 822px;
        text-align: center;
        height:400px;
    }
    .fullWidthImageContainer img {
        width: 90%;
        height: auto;
        margin: 0 auto;
    }
    .contentColumn {
        //width:846px!important;
        width:822px!important;
    }
}
/*Video Player*/
.vjs-crs-theme .vjs-poster, .vjs-promotional-theme .vjs-poster {
    background-size:cover;
}
.vjs-crs-theme .vjs-custom-play-button, .vjs-promotional-theme .vjs-custom-play-button  {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    width:10em;
    height:10em;
    border-radius: 50%;    
    cursor: pointer;
    background: url('../../../assets/icons/play-icon.svg') no-repeat;    
    background-size: cover;
}
.vjs-crs-theme .vjs-custom-pause-button, .vjs-promotional-theme .vjs-custom-pause-button  {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    width:10em;
    height:10em;
    border-radius: 50%;    
    cursor: pointer;
    background: url('../../../assets/icons/pause-icon.svg') no-repeat;
    background-size: cover;
}
@media screen and (min-width: 551px) and (max-width: 768px) {
    .vjs-crs-theme .vjs-custom-play-button, 
    .vjs-crs-theme .vjs-custom-pause-button,
    .vjs-promotional-theme .vjs-custom-play-button, 
    .vjs-promotional-theme .vjs-custom-pause-button  {
        position: absolute;
        left:10px;
        top:10px;
        transform: translate(0,0);
        width:6em;
        height:6em;
    }
}
@media screen and (max-width: 550px) { 
    .vjs-crs-theme .vjs-custom-play-button, 
    .vjs-crs-theme .vjs-custom-pause-button,
    .vjs-promotional-theme .vjs-custom-play-button, 
    .vjs-promotional-theme .vjs-custom-pause-button {
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        width:7em;
        height:7em;
    }
    .vjs-crs-theme, .vjs-promotional-theme {
        height: 300px;
    }
}

@media only screen and (max-width: 1130px) {
    .contentColumn, .fullWidthImageContainer, .fullWidthVideoContainer, .vjs-crs-theme, .vjs-promotional-theme {
        width:100%!important;
    }
    .fullWidthImageContainer {        
        text-align: center;
    }
}

.list-group-item {
    padding:10px 15px!important;
    margin:0!important;    
}

.listGroupItemDiv {
    display: flex;
    align-items:center; 
    cursor: pointer;   
}
.navIcn {
    width:30px;
    height:30px;
    // background:#ccc;
    margin-right: 10px;
}
.navIcn img {
    width:auto;
    height:100%;
}
.nav-play-icon {
    background: url('../../../assets/icons/nav-play-icon.svg') no-repeat;
    background-size: cover;
}
.nav-audio-icon {
    background: url('../../../assets/icons/nav-audio-icon.svg') no-repeat;
    background-size: cover;
}
.nav-doc-icon {
    background: url('../../../assets/icons/nav-doc-icon.svg') no-repeat;
    background-size: cover;
}
.nav-assignment-icon {
    background: url('../../../assets/icons/nav-assignment-icon.svg') no-repeat;
    background-size: cover;
}
.nav-pdf-icon {
    background: url('../../../assets/icons/nav-pdf-icon.svg') no-repeat;
    background-size: cover;
}
.nav-pdf-icon-black {
    background: url('../../../assets/icons/nav-pdf-icon-black.svg') no-repeat;
    background-size: cover;
}
.nav-quiz-icon {
    background: url('../../../assets/icons/nav-quiz-icon.svg') no-repeat;
    background-size: cover;
}
.listEndIcn {
    margin-left: auto;
}







@import "./video-gallery.css";

