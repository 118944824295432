.video-js.vjs-16-9 {
  margin-bottom:50px;
}
.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar,
.vjs-has-started.vjs-user-active.vjs-playing .vjs-control-bar {
  opacity: 1;
}
.vjs-theme .vjs-control-bar {
  height:5em;
  bottom: -50px;
  /*z-index: 999999!important;  */
}
.vjs-fullscreen .vjs-control-bar {
  bottom: 0px;
  background-color: rgba(255, 255, 255, .8)!important;
}
.vjs-fullscreen.vjs-user-inactive .vjs-control-bar, 
.vjs-fullscreen.vjs-user-inactive .vjs-title-bar, 
.vjs-fullscreen.vjs-user-inactive .vjs-progress-control, 
.vjs-fullscreen.vjs-user-inactive .vjs-current-time, 
.vjs-fullscreen.vjs-user-inactive .vjs-duration {
  opacity: 0;
}
.vjs-fullscreen.vjs-user-active .vjs-control-bar, 
.vjs-fullscreen.vjs-user-active .vjs-title-bar, 
.vjs-fullscreen.vjs-user-active .vjs-progress-control, 
.vjs-fullscreen.vjs-user-active .vjs-current-time, 
.vjs-fullscreen.vjs-user-active .vjs-duration {
  opacity: 1;
}

.vjs-has-started.vjs-user-inactive .vjs-progress-control, 
.vjs-has-started.vjs-user-inactive .vjs-current-time, 
.vjs-has-started.vjs-user-inactive .vjs-duration {
  opacity: 0;
}
.vjs-has-started.vjs-user-active .vjs-progress-control, 
.vjs-has-started.vjs-user-active .vjs-current-time, 
.vjs-has-started.vjs-user-active .vjs-duration {
  opacity: 1;
}

.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
  pointer-events: all;
}

.vjs-theme .vjs-button > .vjs-icon-placeholder:before {
  font-size: 2.2em;
  line-height: 2.2;
}
.vjs-theme .vjs-time-control {
  font-size: 1.5em;
}
.vjs-theme .vjs-playback-rate .vjs-playback-rate-value {    
  font-size: 1.55em;
  line-height: 3;    
}
.vjs-theme .vjs-menu-button-popup .vjs-menu .vjs-menu-content {    
  bottom: 3.5em;
}
.vjs-theme .vjs-menu li {        
  font-size: 1.5em;
}
.video-js .vjs-volume-bar {
  margin: 2.3em 0.45em;
}
.video-js .vjs-duration, 
.vjs-no-flex .vjs-duration {
  display: block;
  position: absolute;
  bottom: 60px;
  right: 0;
}
.video-js .vjs-current-time, 
.vjs-no-flex .vjs-current-time {
  display: block;
  position: absolute;
  bottom: 60px;
  left: 0;
}
.video-js .vjs-progress-holder {
  height: 0.4em;
  background-color: rgba(115, 133, 159, 0.3);
  background-color: rgba(0, 0, 0, 0.3);
}
.video-js .vjs-icon-placeholder:before {
  color:#5E5E5E;
}
.video-js .vjs-remaining-time, .video-js .vjs-play-control, .video-js .vjs-fullscreen-control {
  display: none;
}
.vjs-volume-panel {
  width: 10em!important;
}
.vjs-volume-control {
  visibility: visible!important;
  width: 5em!important;
  height: 3em!important;
  opacity: 1!important;
}
.vjs-volume-control .vjs-slider {
  background-color: rgba(94, 94, 94, 0.2)!important;
}
.video-js .vjs-volume-level {
  background-color: rgb(94, 94, 94);
}
.video-js .vjs-volume-level:before {
  color: rgb(94, 94, 94);
}
.vjs-theme .vjs-playback-rate .vjs-playback-rate-value {
  color: rgb(94, 94, 94);
}
.vjs-playback-rate {
  margin-left:auto!important;
}
.baseBarDiv {
  margin-right: 15px;
  color: #5E5E5E;
  cursor: pointer;
}
.baseBarDiv:last-child {
  margin-right: 0;
}
.baseBarIconWrapperWidthAuto {
  width: auto;
  height: 50px;    
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right:0px;
}
.baseBarIconWrapper {
  width: 40px;
  height: 50px;    
  display: flex;
  align-items: center;
  justify-content: center;
}
.baseBarIconWrapperWidthAuto a, .baseBarIconWrapper a {
  text-decoration: none;
  font-size: 14px;
}
.baseBarIconWrapperWidthAuto a:hover, 
.baseBarIconWrapper a:hover {
  color: rgba(94, 94, 94, 0.5);
}
.baseBarIconWrapperWidthAuto a:hover i, 
.baseBarIconWrapper a:hover i {
  opacity:0.2;
}
.baseBarIconWrapperWidthAuto a span, .baseBarIconWrapper a span {
  margin-right:5px;
}
.baseBarIcon {    
  cursor: pointer;
  font-size:15px;
  color:rgb(94, 94, 94);
  display: flex;
  align-items: center;
}
.video-js .vjs-title-bar {
  background: rgba(94, 94, 94, 0.5);
  color: white;
  display: none;
  font-size: 2em;
  padding: .5em;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: left;
}
.video-js.vjs-paused.vjs-has-started .vjs-title-bar,
.video-js.vjs-user-active.vjs-has-started .vjs-title-bar{
  display: block;
}
.video-js.vjs-user-inactive.vjs-has-started .vjs-title-bar{
  display: block;
}
.vjs-fullscreen .vjs-title-bar {
  display: block!important;

}
.vjs-playback-rate .vjs-menu {
  min-width: 116px;  
  left: -36px!important;
  bottom: -3px;
}
.vjs-menu-content {
  bottom: 40px!important;
  background-color: #fff!important;
  border-radius: 10px;
  box-shadow: 0 0 1em rgb(94, 94, 94);
  height: auto!important;
}
.vjs-menu:after {
  content: ' ';
  width: 16px;
  height: auto;
  border-width: 12px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
  position: absolute;
  margin-left: -5px;
  bottom: 16px;
  left: 50%;
  display: flex;
  color: #000;
  align-items: center;
  justify-content: center;
  transform: translate(-6px);
}
.vjs-menu-item {
  color: rgb(94, 94, 94);    
}
.vjs-menu-item:last-child {
  border: none;
}
.vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  max-height:auto;
}
.vjs-menu {
  z-index: 999999!important
}
.vjs-menu li {
  text-align: left!important;
  padding-left:10px;  
}
.vjs-menu-button-popup .vjs-menu .vjs-menu-content li.vjs-menu-item:last-child {
  display:none;
}
.vjs-playback-rate .vjs-menu li:last-child {
  display:block!important;
}
.vjs-menu:after {
  right:-4px;
  left:auto;
  bottom:20px;
}
.video-js .vjs-menu-button-popup .vjs-menu {
    left: auto;
    right: 7px!important;
    bottom:-5px;
    min-width: 7em!important;
    width:auto!important;
}
.vjs-menu li.vjs-selected, 
.vjs-menu li.vjs-selected:focus, 
.vjs-menu li.vjs-selected:hover, 
.js-focus-visible .vjs-menu li.vjs-selected:hover {
  color:rgb(121, 0, 233);
  font-weight:bold;
}
.vjs-menu li.vjs-menu-item:focus, 
.vjs-menu li.vjs-menu-item:hover, 
.js-focus-visible .vjs-menu li.vjs-menu-item:hover {
  background-color:rgb(121, 0, 233);
  color:#fff;
}


/*Feb 1, 2023*/
.video-thumbnails .vjs-tech {
  width:100%!important;
  height:auto;  
}
.baseBarIconWrapperWidthAuto a, .baseBarIconWrapper a {  
  font-size: 1rem;
}
.vjs-big-play-button {
  display: none!important;
}
