.active_tab{
    border: 1px solid #ffffff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background: #ffffff;
    border-top-width: 1px;
    font-weight: 600;
    color: black;
    padding: 20px !important;
}

.pay_cards{
    margin: 5px;
    border: 1px solid #ebebeb;
    padding: 30px;
    background: #ebebeb;
    border-radius: 10px;
}
.shadow_footer{
    justify-content: center;
    padding: 15px;
    font-size: 18px;
    background: #F2F5F7;
    box-shadow: 0px 0px 11px rgb(0 0 0 / 25%);
}
.fakeTextInput{
    border: 1px solid #ced4da;
    border-radius: 8px;
    padding: 10px;
}

