.questionStyle {
    padding: 5px;
    margin-bottom: 10px;
}

.fs-30{
    font-size: 30px;
}

.fs-18{
    font-size: 18px;
}

.urAns{
    background: #556ee6;
    padding: 5px;
    color: white;
}

.questionPagination{
    flex: 1 0;
    border: 1px solid #1890ff;
    margin: 5px;
    border-radius: 25px;
    text-align: center;
}

.arrow-down {
    display: none !important;
}