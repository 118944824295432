.themeBG {
    background-color: #262b37 !important;
}


.cardShadow:hover {
    transition: transform .2s;
    transform: scale(1.03);

}

.cardShadow {
    box-shadow: 'rgb(0 0 0 / 38%) 0px 1px 8px 0px';
    width: 100%;
}


.cardContainer {
    position: relative;
    width: 1100px;
    display: flex;
    align-items: center;
    justify-cardContent: center;
    flex-warp: warp;
    padding: 30px;
}

.cardContainer .cardCard {
    position: relative;
    max-width: 300px;
    height: 215px;
    background-color: #fff;
    margin: 30px 10px;
    padding: 20px 15px;

    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-in-out;
    border-radius: 15px;
}

.cardContainer .cardCard:hover {
    height: 420px;
}


.cardContainer .cardCard .cardImage {
    position: relative;
    width: 260px;
    height: 260px;
    top: -40%;
    left: 8px;
    /* box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2); */
    text-align: center;
    z-index: 1;
}

.cardContainer .cardCard .cardImage img {
    max-width: 100%;
    border-radius: 15px;
}

.cardContainer .cardCard .cardContent {
    position: relative;
    top: -140px;
    padding: 10px 15px;
    color: #111;
    text-align: center;

    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease-in-out;

}

.cardContainer .cardCard:hover .cardContent {
    margin-top: 30px;
    visibility: visible;
    opacity: 1;
    transition-delay: 0.2s;

}