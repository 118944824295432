.aboutuspage {
    padding:0!important;
    margin:60px 0 0 0!important;
    }
    .abtPageBg {
    background-image: radial-gradient(73.23% 190.9% at 69.99% -45.1%, #1B00E9 22.81%, #7900E9 87.66%);
    background-position: top center;
    min-height: 500px;
    width: 100%;
    height: auto; 
    overflow: hidden;
    position: relative;
    }
    .abtPageBgSec{
        background-image: linear-gradient(rgba(58, 73, 86, 0.2) 24.24%, #3A4956 80%);
        position: absolute;
        width: 100%;
        height:100%;
        top:0;
        left:0;
    }
    .abtPageBg .contentContainer {
    width: 100%;
    margin: 2em auto;
    max-width:1366px;
    min-height: 500px;      
    position: relative;
    }
    .contentContainer h3 {
    font-family: 'Bebas Neue', cursive;  
    letter-spacing: 1;
    font-weight: 200;
    font-style: normal;
    font-size:6em;
    }
    .shareText {
    font-family: 'Inter', sans-serif;
    font-size: 1em;
    text-transform: uppercase;
    }
    .socialIcons i {
    font-size: 1.2em;
    /* padding: 5px; */
    color:#fff;
    }
    .proPerformances {
    background-image: linear-gradient(rgba(20,1,128,1) 0%, rgba(67,1,128,1) 100%); /* W3C */    
    background-position: center center;  
    width: 100%;
    height: auto; 
    overflow: hidden;
    }
    .proPerformances .imageContainer {
    width: 100%;
    margin: 0 auto;
    max-width:1366px;
    position: relative;
    max-height: 450px;
    }
    .artistImage {
    opacity: 0.4;
    width: 100%;
    height: 450px;
    margin-top:-50px;
    }
    .ourStryImg {
    width: 100%;
    height: auto; 
    max-width: 1125px;
    margin-top: 50px;
    }
    .storyText, .stryTitCon p {
    width: 60%;
    margin: 0 auto;
    }
    .storyText p, .stryTitCon p {
    text-align: center;
    font-size: 1.2em;
    }
    .stroyImg {
    width: 100%;
    height: auto;
    margin: 0 auto;
    }
    .stryRow {
        padding:30px 0;
    }
    .stryTitCon h3 {
        padding:15px 0;
    }
    .stryImgCon img {
        width:450px;
        height: auto;
    }
    .stryTextCon {
        padding:10px 0;
    }
    @media screen and (max-width: 550px) {   
        .storyText, .stryTitCon p {
            width: 80%;    
        }
        .artistImage {
            margin-top:0;
        }
        .abtPageBg .contentContainer {
            min-height: 360px;
        }
        .proPerformances .imageContainer {
            height: 360px;
        }
        .contentContainer h3, .stryTitCon h3 {
            font-size: 2.4em;
        }
        .stryRow {
            padding:0px 0;
        }
        .stryImgCon {
            justify-content: center!important;
        }
        .stryImgCon img {
            width:100%;
            height:auto;
        }
    }
    @media screen and (min-width: 551px) and (max-width: 768px) {
        .stryImgCon {
            justify-content: center!important;
        }
        .stryImgCon img {
            width:100%;
            height:auto;
        }
    }
    
    /*2feb2023*/
    .txShadow {
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.45);
    }
    .storyTextNew {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 1.5rem;
        text-align: center;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        max-width: 796px;
    }
    .abtH3 {
        font-family: 'Bebas Neue';
        font-style: normal;
        font-weight: 400;
        font-size: 2.875rem;
        text-align: center;
        letter-spacing: 0.15px;
        text-transform: uppercase;
        color: #111111;
    }
    .abtp {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 1.2rem;    
        text-align: center;
        color: #505D68;
    }
    .abtpleft{
        text-align: left;
    }
    .stryContainer {
        max-width: 100%!important;
    }
    .stryRow p {
        max-width: 896px;
        margin: 0 auto;
    }
    .proSubscribeBtn {
        width: 228px;
        height: 52px;
        background: #FFFFFF!important;
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.45);
        border-radius: 40px!important;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 100%;
        text-align: center;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: #7900E9!important;
      }
      .socialIcons a {
        padding:0 8px;
      }
      .abtstxtcntnr {
        padding: 0 120px;
      }