/*
Template Name: magarisa - Admin & Website Template
Author: Eparampara
Version: 1.1.0
Website: http://www.eparampara.com/
Contact: info@eparampara.com
*/

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 
  .header-title {
    font-size: 2.8rem;
  }
  .bodytext-large {
    font-size: 1.25rem;
  }
  .loginMobileBox {        
    margin-top:10px!important;
  }
  .preferenceBtn {    
    font-size: 1rem;
  }
  .editBtnWrapper {
    margin: 0 auto 20px!important;
    text-align: center!important;
  }
  .usrSection {
    flex-direction: column;
    padding: 15px 0 0;    
  }
  .usrSection > div {
    text-align: center;
    margin: 0!important;    
    padding: 0!important;
  }
  .usrSection .d-flex {
    flex-direction: column;
    margin-bottom: 15px;
    width: 100%!important;    
    text-align: center;
  }
  .usrSection .d-flex p {
    margin-bottom: 0!important;
  }
  .shadow_footer .d-flex {
    flex-direction: column;
  }
  .hvntTxt {
    margin-bottom: 10px;
  }
  .shadow_footer .d-flex button {
    flex-direction: row;
  }
  .prefMnu .nav-item {
    width: 100%;
    text-align: center;
  }
  .supportColumn {
    display:flex;
    align-items: center;
    justify-content: center;
  }
  .paragraphTxt {
    font-size:1rem;
  }
  .h3Styling, .cntrlsSection h3 {
    font-size: 2.0rem;
  }
  .h3Styling {
    padding:0 0 10px 0; 
  } 
  .magarisaGradientButton {
    font-size: 1rem;
  }
  .courseSwiper {      
    height: 150px!important;    
  }  
  .masterPageBg {
    height: auto;
  }
  .artistCourseCardWrapper {    
    grid-template-columns: repeat(1, minmax(0, 1fr))!important;    
  }
  .artistCourseCardWrapper .artistCourseCard {
    margin-bottom: 0;
    margin-right: 0;
    width: auto!important;
  }
  /*4feb*/
  .testimonialSec {
    padding-bottom: 0px;
  }
  .registerNowBtn {
    width:auto;
    height: auto;
  }
}


// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) { 
  .loginBoxWrapper {
    margin-top:20px;    
  }
  .loginMobileBox {    
    padding: 0;    
    margin-top:20px;
    padding: 20px;
  }
  .usrPrefContainer {
    margin-top: 0;
  }
  .prefContainer {
    border-radius: 0!important;
    border: none!important;
    padding: 20px 20px 60px;
  }
  .prefMnuWrapper {
    border-radius: 10px;
    border: solid 1px #C2D1D9;
    padding-right: 12px;
    padding-left: 12px;
    padding-top: 0;
    padding-bottom: 0;
    margin-top:15px;    
  }
  .usrProfileSec {    
    height: auto;
  }
  .prefMnu {
    flex-direction: row;
  }
  .prefMnu .navLink {   
    padding: 10px 20px!important;
  }
  .prefMnu .active_tab {    
    border-radius:10px;
  }
  .paragraphTxt {
    font-size:1.25rem;
  }
  .colClass {
    text-align: center;    
    padding: 10px 0;
    justify-content: center;    
  }
  .footerRow {
    display: flex;
    flex-direction: column;
  }
  .courseSwiper {      
    height: 300px;    
  }
  .courseSwiper .swiper-slide {    
    position: relative;
  }  
  .courseSwiper .swiper-slide img {    
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%,-50%);
  } 
  .promotionalPageImg {
    width:100%;
    height: auto;
    opacity: 0.5;
  }   
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .navbar-header {
    height: 60px;
    padding: 0 10px;
  }
  .pageWrapperBox {    
    padding: 60px 20px 20px!important;
  }
  .swiperImageContainer img {
    width: 100%!important;
    height: auto!important;
  }
 
 
  .artistCourseCardWrapper {
    display: grid;    
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap:20px;
    position: relative;    
  }
  .artistCourseCardWrapper .artistCourseCard { 
    width:100%;
  }  
}
@media (max-width: 1365.98px) {
  .hamburgerMenuWrapper {
    display:block;
    z-index: 9; 
  }
  .navColumn {
    display:none;
  }
  .contentColumnWrapper {  
    margin-left:auto;
  }
  
  .contentColumnWrapper .contentColumnDiv {
    max-width:100%;    
    padding: 0 15px;
  }
  .videoGalleryContainer {
    margin:0 -15px;
  }
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) { 
  .pageWrapperBox {    
    padding: 60px 48px 20px;
  }
  .usrProfileSec {
    padding:0 20px;
  }
  .ntLoggedinContentColumnWrapper .contentColumnDiv {
    max-width:100%;
    margin:0 auto;
    padding:0 15px;
  }
}

// Extra large devices (large desktops)
// No media query since the extra-large breakpoint has no upper bound on its width


// X-Small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
  .react-multiple-carousel__arrow--left {
    display: block;
    left:5%;
  }
  .react-multiple-carousel__arrow--right {
    display: block;
    right:5%;
  }
  .cntrlsCaption, .cntrlsSection p {
    display: none;
  }
  .h3Styling {
    padding:0 0 10px 0; 
  }  
  .swiperOne .swiper-wrapper .swiper-slide {
    min-height: 200px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
  .react-multiple-carousel__arrow--left {
    left:10%;
  }
  .react-multiple-carousel__arrow--right {
    right:10%;
  }
  .cntrlsCaption, .cntrlsSection p {
    display: block;
  }
  .swiperOne .swiper-wrapper .swiper-slide {
    min-height: 300px;
  }
  .slide-image {
    width: 100% !important;
    object-fit: cover;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
  .emulated-flex-gap {    
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
  .emulated-flex-gap .item {  
    background: #ececec;  
    text-align: center;
    padding:10px;
    width: 148px;
    height: 140px;
  }
  .react-multiple-carousel__arrow--left {
    left:15%;
  }
  .react-multiple-carousel__arrow--right {
    right:15%;
  }
  .react-multi-carousel-item .ant-card {
    min-width: 255px;
  }
  .react-multi-carousel-item .ant-card.m-3 {
    margin:0.6rem!important;
  }
  .swiperOne .swiper-wrapper .swiper-slide {
    min-height: 360px;
  }  
  // .swiperOne .swiper-wrapper .swiper-slide {
  //   min-height: 300px;
  // }
  .assignmentBtnWrapper {
    width:362px;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
  .otpBox div input {    
    height: 48px;
  }
  .react-multiple-carousel__arrow--left {
    left:30%;
  }
  .react-multiple-carousel__arrow--right {
    right:30%;
  }
  .cntrlsSection h3 {     
    font-size:5.625rem;
  }
  .cntrlsCaption, .cntrlsSection p {    
    font-size: 1.5rem;
  }
  .swiperOne .swiper-wrapper .swiper-slide {
    // min-height: 380px;
    min-height: 380px;
  }
}



// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) { 
  .swiperOne .swiper-wrapper .swiper-slide {
    // min-height: 640px;
    min-height: 400px;
  }
}
@media (min-width: 2500px) { 
  .swiperOne .swiper-wrapper .swiper-slide {
    // min-height: 937px;
    // min-height: 637px;
  }
  .swipercf .swiper-slide {   
    min-height: 625px;
  }
}


@media only screen and (min-width: 1366px) and (max-width: 1536px) {
  .contentColumnWrapper .contentColumnDiv {
    max-width:846px;  
    margin: 0 auto;
  } 
}
@media only screen and (min-width: 1537px){
  .contentColumnWrapper {
    margin-left: 484px!important;
  }
  .contentColumnWrapper .contentColumnDiv {
    max-width:943px!important;  
    margin: 0 auto!important;
    padding: 0;
  } 
}











////8-1-24
// /*
// Template Name: magarisa - Admin & Website Template
// Author: Eparampara
// Version: 1.1.0
// Website: http://www.eparampara.com/
// Contact: info@eparampara.com
// */

// // Extra small devices (portrait phones, less than 576px)
// @media (max-width: 575.98px) { 
//   .header-title {
//     font-size: 2.8rem;
//   }
//   .bodytext-large {
//     font-size: 1.25rem;
//   }
//   .loginMobileBox {        
//     margin-top:10px!important;
//   }
//   .preferenceBtn {    
//     font-size: 1rem;
//   }
//   .editBtnWrapper {
//     margin: 0 auto 20px!important;
//     text-align: center!important;
//   }
//   .usrSection {
//     flex-direction: column;
//     padding: 15px 0 0;    
//   }
//   .usrSection > div {
//     text-align: center;
//     margin: 0!important;    
//     padding: 0!important;
//   }
//   .usrSection .d-flex {
//     flex-direction: column;
//     margin-bottom: 15px;
//     width: 100%!important;    
//     text-align: center;
//   }
//   .usrSection .d-flex p {
//     margin-bottom: 0!important;
//   }
//   .shadow_footer .d-flex {
//     flex-direction: column;
//   }
//   .hvntTxt {
//     margin-bottom: 10px;
//   }
//   .shadow_footer .d-flex button {
//     flex-direction: row;
//   }
//   .prefMnu .nav-item {
//     width: 100%;
//     text-align: center;
//   }
//   .supportColumn {
//     display:flex;
//     align-items: center;
//     justify-content: center;
//   }
//   .paragraphTxt {
//     font-size:1rem;
//   }
//   .h3Styling, .cntrlsSection h3 {
//     font-size: 2.0rem;
//   }
//   .h3Styling {
//     padding:0 0 10px 0; 
//   } 
//   .magarisaGradientButton {
//     font-size: 1rem;
//   }
//   .courseSwiper {      
//     height: 150px!important;    
//   }  
//   .masterPageBg {
//     height: auto;
//   }
//   .artistCourseCardWrapper {    
//     grid-template-columns: repeat(1, minmax(0, 1fr))!important;    
//   }
//   .artistCourseCardWrapper .artistCourseCard {
//     margin-bottom: 0;
//     margin-right: 0;
//     width: auto!important;
//   }
//   /*4feb*/
//   .testimonialSec {
//     padding-bottom: 0px;
//   }
//   .registerNowBtn {
//     width:auto;
//     height: auto;
//   }
// }


// // Small devices (landscape phones, less than 768px)
// @media (max-width: 767.98px) { 
//   .loginBoxWrapper {
//     margin-top:20px;    
//   }
//   .loginMobileBox {    
//     padding: 0;    
//     margin-top:20px;
//     padding: 20px;
//   }
//   .usrPrefContainer {
//     margin-top: 0;
//   }
//   .prefContainer {
//     border-radius: 0!important;
//     border: none!important;
//     padding: 20px 20px 60px;
//   }
//   .prefMnuWrapper {
//     border-radius: 10px;
//     border: solid 1px #C2D1D9;
//     padding-right: 12px;
//     padding-left: 12px;
//     padding-top: 0;
//     padding-bottom: 0;
//     margin-top:15px;    
//   }
//   .usrProfileSec {    
//     height: auto;
//   }
//   .prefMnu {
//     flex-direction: row;
//   }
//   .prefMnu .navLink {   
//     padding: 10px 20px!important;
//   }
//   .prefMnu .active_tab {    
//     border-radius:10px;
//   }
//   .paragraphTxt {
//     font-size:1.25rem;
//   }
//   .colClass {
//     text-align: center;    
//     padding: 10px 0;
//     justify-content: center;    
//   }
//   .footerRow {
//     display: flex;
//     flex-direction: column;
//   }
//   .courseSwiper {      
//     height: 300px;    
//   }
//   .courseSwiper .swiper-slide {    
//     position: relative;
//   }  
//   .courseSwiper .swiper-slide img {    
//     position: absolute;
//     top: 50%;
//     left:50%;
//     transform: translate(-50%,-50%);
//   } 
//   .promotionalPageImg {
//     width:100%;
//     height: auto;
//     opacity: 0.5;
//   }   
// }

// // Medium devices (tablets, less than 992px)
// @media (max-width: 991.98px) {
//   .navbar-header {
//     height: 60px;
//     padding: 0 10px;
//   }
//   .pageWrapperBox {    
//     padding: 60px 20px 20px!important;
//   }
//   .swiperImageContainer img {
//     width: 100%!important;
//     height: auto!important;
//   }
 
 
//   .artistCourseCardWrapper {
//     display: grid;    
//     grid-template-columns: repeat(2, minmax(0, 1fr));
//     gap:20px;
//     position: relative;    
//   }
//   .artistCourseCardWrapper .artistCourseCard { 
//     width:100%;
//   }  
// }
// @media (max-width: 1365.98px) {
//   .hamburgerMenuWrapper {
//     display:block;
//     z-index: 9; 
//   }
//   .navColumn {
//     display:none;
//   }
//   .contentColumnWrapper {  
//     margin-left:auto;
//   }
  
//   .contentColumnWrapper .contentColumnDiv {
//     max-width:100%;    
//     padding: 0 15px;
//   }
//   .videoGalleryContainer {
//     margin:0 -15px;
//   }
// }

// // Large devices (desktops, less than 1200px)
// @media (max-width: 1199.98px) { 
//   .pageWrapperBox {    
//     padding: 60px 48px 20px;
//   }
//   .usrProfileSec {
//     padding:0 20px;
//   }
//   .ntLoggedinContentColumnWrapper .contentColumnDiv {
//     max-width:100%;
//     margin:0 auto;
//     padding:0 15px;
//   }
// }

// // Extra large devices (large desktops)
// // No media query since the extra-large breakpoint has no upper bound on its width


// // X-Small devices (portrait phones, less than 576px)
// // No media query for `xs` since this is the default in Bootstrap

// // Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) { 
//   .react-multiple-carousel__arrow--left {
//     display: block;
//     left:5%;
//   }
//   .react-multiple-carousel__arrow--right {
//     display: block;
//     right:5%;
//   }
//   .cntrlsCaption, .cntrlsSection p {
//     display: none;
//   }
//   .h3Styling {
//     padding:0 0 10px 0; 
//   }  
//   .swiperOne .swiper-wrapper .swiper-slide {
//     // min-height: 200px;
//     min-height: 100px;
//   }
// }

// // Medium devices (tablets, 768px and up)
// @media (min-width: 768px) { 
//   .react-multiple-carousel__arrow--left {
//     left:10%;
//   }
//   .react-multiple-carousel__arrow--right {
//     right:10%;
//   }
//   .cntrlsCaption, .cntrlsSection p {
//     display: block;
//   }
//   .swiperOne .swiper-wrapper .swiper-slide {
//     // min-height: 300px;
//     min-height: 150px;
//   }
//   .slide-image {
//     width: 100% !important;
//     object-fit: cover;
//   }
// }

// // Large devices (desktops, 992px and up)
// @media (min-width: 992px) { 
//   .emulated-flex-gap {    
//     display: grid;
//     grid-template-columns: repeat(5, minmax(0, 1fr));
//     grid-column-gap: 20px;
//     grid-row-gap: 20px;
//   }
//   .emulated-flex-gap .item {  
//     background: #ececec;  
//     text-align: center;
//     padding:10px;
//     width: 148px;
//     height: 140px;
//   }
//   .react-multiple-carousel__arrow--left {
//     left:15%;
//   }
//   .react-multiple-carousel__arrow--right {
//     right:15%;
//   }
//   .react-multi-carousel-item .ant-card {
//     min-width: 255px;
//   }
//   .react-multi-carousel-item .ant-card.m-3 {
//     margin:0.6rem!important;
//   }
//   .swiperOne .swiper-wrapper .swiper-slide {
//     min-height: 155px;
//     // min-height: 360px;
//   }  
//   .swiperOne .swiper-wrapper .swiper-slide {
//     min-height: 150px;
//     // min-height: 300px;
//   }
//   .assignmentBtnWrapper {
//     width:362px;
//   }
// }

// // X-Large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) { 
//   .otpBox div input {    
//     height: 48px;
//   }
//   .react-multiple-carousel__arrow--left {
//     left:30%;
//   }
//   .react-multiple-carousel__arrow--right {
//     right:30%;
//   }
//   .cntrlsSection h3 {     
//     font-size:5.625rem;
//   }
//   .cntrlsCaption, .cntrlsSection p {    
//     font-size: 1.5rem;
//   }
//   .swiperOne .swiper-wrapper .swiper-slide {
//     // min-height: 440px;
//     min-height: 240px;
//   }
// }

// // XX-Large devices (larger desktops, 1400px and up)
// @media (min-width: 1400px) { 
//   .swiperOne .swiper-wrapper .swiper-slide {
//     max-height: 440px;
//     // min-height: 640px;
//   }
// }
// @media (min-width: 2500px) { 
//   .swiperOne .swiper-wrapper .swiper-slide {
//     // min-height: 937px;
//     min-height: 737px;
//   }
//   .swipercf .swiper-slide {   
//     min-height: 625px;
//   }
// }


// @media only screen and (min-width: 1366px) and (max-width: 1536px) {
//   .contentColumnWrapper .contentColumnDiv {
//     max-width:846px;  
//     margin: 0 auto;
//   } 
// }
// @media only screen and (min-width: 1537px){
//   .contentColumnWrapper {
//     margin-left: 484px!important;
//   }
//   .contentColumnWrapper .contentColumnDiv {
//     max-width:943px!important;  
//     margin: 0 auto!important;
//     padding: 0;
//   } 
// }