
@font-face {
    font-family: "Material Design Icons";
    src: url("../../../assets/fonts/materialdesignicons-webfont.eot?v=5.8.55");
    src: url("../../../assets/fonts/materialdesignicons-webfont.eot?#iefix&v=5.8.55") format("embedded-opentype"), url("../../../assets/fonts/materialdesignicons-webfont.woff2?v=5.8.55") format("woff2"), url("../../../assets/fonts/materialdesignicons-webfont.woff?v=5.8.55") format("woff"), url("../../../assets/fonts/materialdesignicons-webfont.ttf?v=5.8.55") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
.react-multi-carousel-list {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative
}

.react-multi-carousel-track {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    position: relative;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    will-change: transform, transition
}

.react-multiple-carousel__arrow {
    position: absolute;
    outline: 0;
    transition: all .5s;
    border-radius: 8px !important;
    z-index: 1000;
    border: 0;
    background: rgba(0, 0, 0, 0.5);
    min-width: 30px !important;
    min-height: 60px !important;
    opacity: 1;
    cursor: pointer
}

.react-multiple-carousel__arrow:hover {
    background: rgba(0, 0, 0, 0.8)
}

.react-multiple-carousel__arrow::before {
    font-size: 20px;
    color: #fff;
    display: block;
    font-family: revicons;
    text-align: center;
    z-index: 2;
    position: relative
}

.react-multiple-carousel__arrow:disabled {
    cursor: default;
    background: rgba(0, 0, 0, 0.5)
}

.react-multiple-carousel__arrow--left {
    left: 0 !important;
    // calc(4% + 1px)
}

.react-multiple-carousel__arrow--left::before {
    font: normal normal normal 24px/1 "Material Design Icons";
    content: "\F004D";
}

.react-multiple-carousel__arrow--right {
    right: 0 !important;
    // calc(4% + 1px)
}

.react-multiple-carousel__arrow--right::before {
    font: normal normal normal 24px/1 "Material Design Icons";
    content: "\F0054";
}

.react-multi-carousel-dot-list {
    position: absolute;
    bottom: 0;
    display: flex;
    left: 0;
    right: 0;
    justify-content: center;
    margin: auto;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center
}

.react-multi-carousel-dot button {
    background: #ae8989;
    display: inline-block;
    width: 20px;
    height: 6px;
    border-radius: 5px;
    opacity: 1;
    padding: 5px 5px 5px 5px;
    box-shadow: none;
    transition: width .5s;
    border-width: 0px;
    border-style: solid;
    border-color: grey;
    padding: 0;
    margin: 0;
    margin-right: 6px;
    outline: 0;
    cursor: pointer
}

.react-multi-carousel-dot button:hover:active {
    background: #705353;
}

.react-multi-carousel-dot--active button {
    background: #705353;
    width: 50px;
    height: 6px;
}

.react-multi-carousel-item {
    transform-style: preserve-3d;
    backface-visibility: hidden
}

@media all and (-ms-high-contrast:none),
(-ms-high-contrast:active) {
    .react-multi-carousel-item {
        flex-shrink: 0 !important
    }

    .react-multi-carousel-track {
        overflow: visible !important
    }
}

[dir='rtl'].react-multi-carousel-list {
    direction: rtl
}

.rtl.react-multiple-carousel__arrow--right {
    right: auto;
    left: 0 !important;
    // calc(4% + 1px)
}

.rtl.react-multiple-carousel__arrow--right::before {
    font: normal normal normal 24px/1 "Material Design Icons";
    content: "\F0B53" !important
}

.rtl.react-multiple-carousel__arrow--left {
    left: auto;
    right: 0 !important;
    // calc(4% + 1px)
}

.rtl.react-multiple-carousel__arrow--left::before {
    font: normal normal normal 24px/1 "Material Design Icons";
    content: "\F004D" !important
}

.custom-dot-list-style{
    bottom:0px
}