.root-react-component-countdown-timer .right {
    direction: ltr; }
  
  .root-react-component-countdown-timer .border .count {
    height: auto;
    border: 1px solid #cfcfcf;
    border-radius: 5px;
    margin: 2px;
    padding: 5px 5px;
    flex: 1; }
  
  .root-react-component-countdown-timer .border.responsive .split {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 8px; }
  
  .root-react-component-countdown-timer .countBoxItem {
    min-width: 30px; }
  
  .root-react-component-countdown-timer .responsive {
    display: flex !important; }
    .root-react-component-countdown-timer .responsive .count {
      flex: 1;
      text-align: center; }
  
  .root-react-component-countdown-timer .inline .count {
    display: inline-block; }
  
  .root-react-component-countdown-timer .inline.left {
    display: inline-block; }
  
  .root-react-component-countdown-timer .inline.right {
    direction: rtl;
    display: inline-flex; }
  
  .root-react-component-countdown-timer .countBox {
    display: flex; }
    .root-react-component-countdown-timer .countBox span.split {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 0px; }
    .root-react-component-countdown-timer .countBox.border span.split {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: 10px; }
    .root-react-component-countdown-timer .countBox .label {
      text-align: center; }
    .root-react-component-countdown-timer .countBox.left {
      display: inline-flex;
      direction: rtl; }
    .root-react-component-countdown-timer .countBox.right {
      direction: ltr; }
    .root-react-component-countdown-timer .countBox .countBoxItem {
      flex: 1; }
      .root-react-component-countdown-timer .countBox .countBoxItem .count {
        text-align: center; }
  
  .count-down-react-box .count-down-react .title {
    border-bottom: 1px solid #ccc;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 0;
    margin: 50px 0 0;
    background-color: #4db1c2;
    color: #fff; }
  
  .count-down-react {
    width: 800px;
    padding: 20px;
    margin: 0 auto;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 1px 6px rgba(0, 0, 0, 0.12);
    background-color: #fff; }
  
  .count-down-react pre {
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    background-color: #f6f8fa;
    border-radius: 3px; }
  
  .count-down-react-box .count-down-react .root-react-component-countdown-timer {
    margin: 15px 0; }
  