
.videoGalleryContainer {
    position: relative;
}
.videoGalleryContainerOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
}
.videoGalleryContainerOverlay.show {
    display: block!important;
}
.swiper {
    width: 100%;
    height: auto;
    /* background-color: aqua; */
}
.swiperOne,  .swiper-slide {
    width:100%;
    height:auto;
    min-height: max-content !important;
    /* margin-bottom: 10px; */
}

.video-thumbnails {
    position: absolute!important;
    /* bottom: 60px; */
    left: 0;
    display: flex;
    visibility: hidden;
    opacity: 0;    
}

.showGallery .video-thumbnails {
    visibility: visible;
    opacity: 1;
    padding: 0 20px 10px;    
}
.video-thumb {
    background:#ccc;
    /* border: solid 5px #fff; */
    cursor: pointer;
}
.video-thumb > .video-js {
    margin-bottom: 0;
}
.video-thumb > .video-js > .vjs-control-bar {
    display: none!important;
}
.vjs-thumb-gallery-title {
    padding: 10px 0;
    font-size: 15px;
    background: rgba(0,0,0,0.5);
    position: absolute;
    width: 100%;
    bottom: 0;
    font-weight: bold;
}

.closeBtnContainer {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    top: 52%;
    cursor: pointer;
    /* background: rgb(121, 0, 233); */
    border-radius: 20px;
    border:solid 2px #ccc;
    text-align: center;
}
.closeBtn {
    font-size: 16px;
    font-weight:bold;
    color:#ccc;
}
.vjs-fullscreen .closeBtnContainer {
    top: 40px;
}
.hide {
    display: none;
}
.swiper-button-next, 
.swiper-button-prev {
    background: rgba(255, 255, 255, 0.3);
    padding:25px;
    text-align: center;
}
.swiper-button-prev:after, 
.swiper-button-next:after {
    font-size:24px!important;
    color:#fff;
}
.swiper-button-prev, .swiper-button-next {

}
.swiper-button-next, 
.swiper-rtl .swiper-button-prev {
    right:0;
}
.swiper-button-next, 
.swiper-rtl .swiper-button-prev {
    right:0!important;
}
.swiper-button-prev, 
.swiper-rtl .swiper-button-next {
    left:0!important;
}
